// Layout.js
import React from "react";
import UserNavbar from "./UserNavbar";

const UserLayout = ({ children }) => {
  return (
    <div>
      <UserNavbar />
      {children}
    </div>
  );
};

export default UserLayout;
