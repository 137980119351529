import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomModal from "../Modal";
import { DeleteOption, IsCorrectOption, Toast, ToastType } from "../../utils";
import axios from "axios";
import "../../styles/EditQuestion.css";

function EditQuestion({
  setUpdateEvent,
  updateEvent,
  questionId,
  preQuestion,
  preOptions,
}) {
  const [question, setQuestion] = useState(preQuestion ? preQuestion : "");
  const [options, setOptions] = useState(preOptions ? preOptions : "");
  const token = useSelector((state) => state.user?.token);

  const handleAddOption = () => {
    setOptions([...options, { optionsName: "", isCorrect: false }]);
  };

  // Handler for changing the optionsName
  const handleOptionNameChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].optionsName = value;
    setOptions(updatedOptions);
  };

  // Handler for changing the isCorrect value
  const handleIsCorrectChange = (index, value) => {
    const updatedOptions = options.map((option, i) => ({
      ...option,
      isCorrect: i === index && value,
    }));
    setOptions(updatedOptions);
  };
  const handleDeleteOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    console.log(newOptions);
    setOptions(newOptions);
  };

  const handleSubmit = () => {
    const hasCorrectOption = options.some((option) => option.isCorrect);

    if (!hasCorrectOption) {
      Toast("Please select a correct option ", ToastType.ERROR, 2000);
      return;
    }
    const formData = {
      id: questionId,
      question: question,
      options: options.map((option) => ({
        optionsName: option.optionsName,
        isCorrect: option.isCorrect,
      })),
      // Replace this with the actual quizId
    };

    console.log(formData);

    // API call to submit the question with options
    axios
      .put(
        `${process.env.REACT_APP_API_BASEURL}/questions/updateQuestionWithOptions/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Replace with your actual access token
          },
        }
      )
      .then((response) => {
        Toast("Question updated Succesfully", ToastType.SUCCESS, 2000);

        console.log(JSON.stringify(response.data));
        setUpdateEvent({ ...updateEvent, questions: !updateEvent.questions });
      })
      .catch((error) => {
        console.log(error);
      });

    // Close the modal
    // onClose();
  };

  useEffect(() => {
    setQuestion(preQuestion ? preQuestion : "");
    setOptions(preOptions ? preOptions : "");
  }, [preQuestion, updateEvent.changeEditQuest]);

  return (
    <>
      <div className="add-question-main">
        <span className="main-heading">Edit Question</span>
        <div className="add-question-input-parent">
          <label className="form-label" htmlFor="question">
            Question:
          </label>
          <input
            type="text"
            id="question"
            className="form-input"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        {options.map((option, index) => (
          <div key={index} className="option-container">
            <div className="select-correct-option">
              <IsCorrectOption
                id={`correct-${index}`}
                className={option.isCorrect ? "selected" : ""}
                checked={option.isCorrect}
                onClick={(e) =>
                  handleIsCorrectChange(index, option.isCorrect ? false : true)
                }
              />
            </div>
            <div className="options-input-parent">
              <label className="form-label" htmlFor={`option-${index}`}>
                {`Option ${index + 1}:`}
              </label>
              <input
                type="text"
                id={`option-${index}`}
                className="form-input"
                value={option.optionsName}
                onChange={(e) => handleOptionNameChange(index, e.target.value)}
              />
            </div>
            <DeleteOption onClick={() => handleDeleteOption(index)} />
          </div>
        ))}
        <div className="button-group">
          <button className="button" onClick={handleAddOption}>
            Add Option
          </button>
          <button className="button" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default EditQuestion;
