import React, { useEffect, useState } from "react";
import useApi from "../../components/hooks/useApi";
import "../../styles/Events.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CreateEvent from "../../components/quiz/CreateEvent";
import axios from "axios";
import EventGrid from "../../components/EventGrid";
import { Toast, ToastType } from "../../utils";

function Events() {
  const token = useSelector((state) => state.user?.token);
  const user = useSelector((state) => state.user);
  const [addEventModalOpen, setAddEventModalOpen] = useState(false);

  const [updateEvent, setUpdateEvent] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingForCopyingEvent, setLoadingForCopyingEvent] = useState({
    status: false,
    index: -1,
  });
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/getAllQuiz`,
          {
            params: "",
            headers: {
              Authorization: token,
              // Add any other headers if needed
            },
          }
        );
        console.log(response.data?.response?.result?.rows);
        setEventData(response.data?.response?.result?.rows);
        // setEventData([]);
        setError(null);
      } catch (error) {
        console.log(error);
        if (
          error.response?.data === "Unauthorized" &&
          error.response?.status === 401
        ) {
          localStorage.removeItem("UserInfo");
          localStorage.removeItem("token");
          dispatch({ type: "LOGOUT" });
        }
        setEventData(null);
        setError(error.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, updateEvent]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (eventData) {
      // Assuming your API response has a 'createdAt' field for the event time
      const eventsWithLocalTime = eventData.map((event) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = {
          timeZone: userTimeZone,
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        };
        const localTimeString = new Date(event.createdAt).toLocaleString(
          "en-US",
          options
        );

        // Add the localTimeString to the event object
        return { ...event, localTimeString };
      });

      setEvents(eventsWithLocalTime);
    }
  }, [eventData]);

  const copyEventFunction = async (eventId, eventCardIndex) => {
    try {
      setLoadingForCopyingEvent({ status: true, index: eventCardIndex });
      // Create a new quiz event
      const createQuizResponse = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/quiz/copyEvent/?eventId=${eventId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      console.log("Quiz created successfully", createQuizResponse.data);
      Toast("Quiz copied successfully", ToastType.SUCCESS, 2000);
      setUpdateEvent(!updateEvent);
    } catch (error) {
      console.error(
        "Error copying quiz:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setLoadingForCopyingEvent({ status: false, index: -1 });
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  if (eventData.length > 0)
    return (
      <div className="events-list-container">
        <h2>Event List</h2>
        <div className="event-add-btn">
          <button className="button" onClick={() => setAddEventModalOpen(true)}>
            Add Event
          </button>
        </div>
        <EventGrid
          events={events}
          copyEvent={copyEventFunction}
          loadingForCopyingEvent={loadingForCopyingEvent}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
        />
        {addEventModalOpen ? (
          <CreateEvent
            isOpen={addEventModalOpen}
            onClose={() => setAddEventModalOpen(false)}
            setUpdateEvent={setUpdateEvent}
            updateEvent={updateEvent}
          />
        ) : null}
      </div>
    );
  else
    return (
      <div className="events-list-container">
        <h2>Event List</h2>
        <div className="event-add-btn">
          <button className="button" onClick={() => setAddEventModalOpen(true)}>
            Add Event
          </button>
        </div>
        <EventGrid events={[]} />
        {addEventModalOpen ? (
          <CreateEvent
            isOpen={addEventModalOpen}
            onClose={() => setAddEventModalOpen(false)}
            setUpdateEvent={setUpdateEvent}
            updateEvent={updateEvent}
          />
        ) : null}
      </div>
    );
}

export default Events;
