// Navbar.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DarkModeToggle from "./DarkModeToggle";
import "../styles/Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const isDarkMode = JSON.parse(localStorage.getItem("IsDarkMode"));

  const user = useSelector((state) => state.user);
  // console.log(user);
  const navigate = useNavigate();
  const handleAdminLogout = () => {
    // Implement your logout logic here
    // For example, clear user data from localStorage and redirect to login page
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
    // window.location.reload();
    navigate("/");
  };

  return (
    <div className="navbar">
      <div style={styles.leftSection}>
        {/* Profile button (link to user profile page, adjust the path accordingly) */}
        {user ? (
          <>
            <Link to="/admin/profile" style={styles.link}>
              Profile
            </Link>
            <Link to="/events" style={styles.link}>
              Events
            </Link>
            <Link to="/announcements" style={styles.link}>
              Announcements
            </Link>
          </>
        ) : null}
      </div>
      <div style={styles.rightSection}>
        {/* Logout button */}
        {user ? (
          // If user is logged in, show the logout button
          <div className="navbarRight">
            <button onClick={handleAdminLogout} className="navbtn logout-btn">
              Logout
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                fill="currentColor"
              >
                <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
              </svg> */}
            </button>
            <DarkModeToggle />
          </div>
        ) : (
          // If user is not logged in, show the login button
          <button onClick={() => navigate("/login")} style={styles.loginButton}>
            Login
          </button>
        )}
      </div>
    </div>
  );
};

const styles = {
  leftSection: {
    flex: 1,
    textAlign: "left",
  },
  rightSection: {
    flex: 1,
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    margin: "0 10px",
  },
};

export default Navbar;
