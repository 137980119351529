import React from "react";
import CustomModal from "../Modal";
import { useSelector } from "react-redux";
import axios from "axios";
import { Toast, ToastType } from "../../utils";

function EventDeleteConfirmation({
  isOpen,
  onClose,
  setUpdateEvent,
  updateEvent,
  event,
}) {
  const token = useSelector((state) => state.user?.token);

  const DeleteEventFunction = async (eventId) => {
    try {
      // Create a new quiz event
      console.log(token);
      const createQuizResponse = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/quiz/deleteEvent/?eventId=${eventId}`,
        {
          headers: {
            Authorization: token,
            // Include any other required headers
          },
        }
      );

      // console.log("Quiz created successfully", createQuizResponse.data);
      Toast("Quiz copied successfully", ToastType.SUCCESS, 2000);
      setUpdateEvent(!updateEvent);
    } catch (error) {
      console.error(
        "Error copying quiz:",
        error.response ? error.response.data : error.message
      );
    }
  };
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="create-quiz-event-container">
        <h3>Are you sure you want to delete the quiz?</h3>

        <div className="form-group">
          <span className="delete-quiz-span-title">Quiz Name:</span>
          <p>{event ? event.Name : ""}</p>
        </div>

        <div className="form-group">
          <span className="delete-quiz-span-title">Quiz Description:</span>
          <p>{event ? event.details : ""}</p>
        </div>
        <button
          className="button delete-button"
          onClick={() => DeleteEventFunction(event ? event.id : null)}
        >
          Confirm Delete
        </button>
      </div>
    </CustomModal>
  );
}

export default EventDeleteConfirmation;
