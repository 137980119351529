import React, { useEffect, useState } from "react";
import AdminLogin from "../../components/AdminLogin";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Admin() {
  const [userData, setUserData] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  console.log(user);
  useEffect(() => {
    const storedUser = localStorage.getItem("UserInfo");

    if (user && storedUser) {
      setIsLoggedIn(true);
      setUserData(JSON.parse(storedUser));
      navigate("/events");
    }
  }, [isLoggedIn, navigate, user]);

  return (
    <div>
      {isLoggedIn && userData ? (
        <div>
          <h2>Admin Login</h2>
          <p>Welcome back, {userData?.profile?.email}!</p>
        </div>
      ) : (
        <AdminLogin setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  );
}

export default Admin;
