import { requestModel } from "../models/requestModel";
import { requestMethod } from "../constants/constants";
import { axiosRequest } from "../config/axios/axiosRequest";
import { CommanResponse } from "../models/commonResponse";

import { ResponseStatus } from "../interfaces/enum";

const commonResponse = (response) => {
  const responseOBJ = new CommanResponse();
  responseOBJ.Status = response?.data?.responseStatus === 1;
  responseOBJ.Result = response?.data;
  responseOBJ.ResponseStatus = response.status;
  return responseOBJ;
};
const commonErrorResponse = (error) => {
  const responseOBJ = new CommanResponse();
  if (error.response?.status === ResponseStatus.Unauthorized) {
    // Toast(error?.message, ToastType.ERROR, 6000);
    // localStorage.clear();
    // if (
    //     !['login', 'forgotPassword'].some((endpoints) => window.location.href.split('/').some((d) => d.toLowerCase() === endpoints.toLowerCase()))
    // )
    //     window.location.href = window.location.origin + '/login';
  }
  if (error.response) {
    // 5543e2
    responseOBJ.Message = error.message;
    responseOBJ.ResponseStatus = error.response.status;
    responseOBJ.Result = error?.response?.data;
    responseOBJ.Status = false;
  } else if (error.request) {
    responseOBJ.Message = JSON.stringify(error.request);
    responseOBJ.Result = error?.response?.data;
    responseOBJ.Status = false;
  } else {
    responseOBJ.Message = error.message;
    responseOBJ.Result = error?.response?.data;
    responseOBJ.Status = false;
  }
  return responseOBJ;
};
const GET = async (url, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.GET;
    reqestObj.url = url;
    reqestObj.params = params;
    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
const POST = async (url, body = {}, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.POST;
    reqestObj.url = url;
    reqestObj.data = body;
    reqestObj.params = params;
    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
const POST_FROM_DATA = async (url, body = {}, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.POST;
    reqestObj.url = url;
    reqestObj.headers = { "Content-Type": "multipart/form-data" };
    reqestObj.data = body;
    reqestObj.params = params;
    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
const PUT = async (url, body = {}, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.PUT;
    reqestObj.url = url;
    reqestObj.data = body;
    reqestObj.params = params;
    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
const PATCH = async (url, body = {}, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.PATCH;
    reqestObj.url = url;
    reqestObj.data = body;
    reqestObj.params = params;

    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
const DELETE = async (url, body = {}, params = {}) => {
  const reqestObj = new requestModel();
  try {
    reqestObj.method = requestMethod.DELETE;
    reqestObj.url = url;
    reqestObj.data = body;
    reqestObj.params = params;
    const response = await axiosRequest.request(reqestObj);
    if (response) return commonResponse(response);
  } catch (error) {
    return commonErrorResponse(error);
  }
};
export { GET, POST, PUT, DELETE, PATCH, POST_FROM_DATA };
