import axios from "axios";
import React, { useState } from "react";
import CustomModal from "../Modal";
import { useSelector } from "react-redux";
import { Toast, ToastType } from "../../utils";
import "../../styles/CreateEvent.css";

function CreateEvent({ isOpen, onClose, setUpdateEvent, updateEvent }) {
  const user = useSelector((state) => state.user);
  console.log(user);
  const token = useSelector((state) => state.user?.token);
  const [quizName, setQuizName] = useState(""); // State for quiz name
  const [quizDescription, setQuizDescription] = useState("");

  const createQuizEvent = async () => {
    try {
      // Create a new quiz event
      const createQuizResponse = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/quiz/createquiz`,
        {
          Name: quizName,
          details: quizDescription,
          userId: user.profile?.id,
          status: 0,
          // Include other required fields in the request body
        },
        {
          headers: {
            Authorization: token,
            // Include any other required headers
          },
        }
      );

      console.log("Quiz created successfully", createQuizResponse.data);
      Toast("Quiz created successfully", ToastType.SUCCESS, 2000);
      setUpdateEvent(!updateEvent);
      onClose();
    } catch (error) {
      console.error(
        "Error creating quiz event:",
        error.response ? error.response.data : error.message
      );
    }
  };
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="create-quiz-event-container">
        <h2>Create Quiz Event</h2>

        <div className="form-group">
          <label>Quiz Name:</label>
          <input
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Quiz Description:</label>
          <textarea
            value={quizDescription}
            rows={10}
            onChange={(e) => setQuizDescription(e.target.value)}
          />
        </div>

        <button className="button" onClick={createQuizEvent}>
          Create Event
        </button>
      </div>
    </CustomModal>
  );
}

export default CreateEvent;
