import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import AccordionPanel from "../../components/singleEvent/AccordionPanel";
import AddQuestion from "../../components/questions/AddQuestion";
import EditQuestion from "../../components/questions/EditQuestion";
import {
  AddQuestionSVG,
  BackArrowSVG,
  DeleteQuestionSVG,
  StartIcon,
  StopIcon,
  Toast,
  ToastType,
} from "../../utils";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "../../styles/SingleEvent.css";
import QuestionDeleteConfirmation from "../../components/questions/QuestionDeleteConfirmation";

const SingleEvent = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [activeQuestionforEdit, setActiveQuestionforEdit] = useState(0);
  const [addQuestionComponent, setAddQuestionComponent] = useState(false);
  const [updateEvent, setUpdateEvent] = useState({
    details: false,
    questions: false,
    changeEditQuest: -1,
  });
  const [questions, setQuestions] = useState([]);
  const [deleteConfirmationQuestion, setDeleteConfirmationQuestion] =
    useState("");
  const [loading, setLoading] = useState({
    updateEventStatus: false,
    updateShowResult: false,
  });
  const [
    showQuestionDeleteConfirmationModel,
    setShowQuestionDeleteConfirmationModel,
  ] = useState(false);
  const token = useSelector((state) => state.user?.token);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/questions/getAllQuestionsWithOptionsByEventId?eventId=${id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const data = response.data.response?.result?.questionsWithOptions;
        console.log(data);
        setActiveQuestionforEdit(data.length >= 0 ? data[0] : null);

        setQuestions(data || []);
      } catch (error) {
        console.error("Error fetching questions data:", error);
      }
    };

    // Call the functions to fetch data

    fetchQuestionsData();
  }, [id, token, updateEvent.questions]);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/getQuizById?id=${id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data.response.result.events);
        // Assuming response.data.Result contains event details
        setEventDetails(response.data.response.result.events);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    fetchEventData();
  }, [id, token, updateEvent.details]);

  const handleEventStatusChange = async (id, status) => {
    setLoading({ ...loading, updateEventStatus: true });
    try {
      const updateStatus = status ? 0 : 1;
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/quiz/changeStatus?id=${id}&status=${updateStatus}`;
      console.log(token);
      const response = await axios.post(apiUrl, true, {
        headers: {
          Authorization: token,
        },
      });

      console.log(response.data);
      setLoading({ ...loading, updateEventStatus: false });
      Toast("Updated Succesfully", ToastType.SUCCESS, 1000);
      setUpdateEvent({
        ...updateEvent,
        details: !updateEvent.details,
        questions: !updateEvent.questions,
      });
      // Handle success, update your component state, show a message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show an error message, etc.
    }
  };

  const handleQuestionStatusChange = async (eventId, questionId) => {
    try {
      // Make API call to delete question

      const data = { quizId: eventId, queId: questionId };
      await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/questions/updateVisibility/`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setUpdateEvent({ ...updateEvent, questions: !updateEvent.questions });
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleQuestResultStatusChange = async (questionId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/questions/updateResultStatus/?id=${questionId}`
      );
      console.log(response.data.success);
      setUpdateEvent({ ...updateEvent, questions: !updateEvent.questions });
      Toast("Updated Succesfully", ToastType.SUCCESS, 1000);
    } catch (error) {
      console.error("Error fetching total questions:", error);
    }
  };

  const handleLeftQuestionListSelect = (question) => {
    setActiveQuestionforEdit(question);
    setAddQuestionComponent(false);

    // Check if the clicked question is the one already being edited
    if (parseInt(question.id) !== updateEvent.changeEditQuest) {
      // If a different question is clicked, set changeEditQuest to 1 to display EditQuestion

      setUpdateEvent((prevEvent) => ({
        ...prevEvent,
        changeEditQuest: question.id,
      }));
    }
  };
  const handlePresenterClick = () => {
    window.open("/events/presenter");
  };

  const handleShowResultForQuiz = async (Eventid) => {
    try {
      console.log(token);
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASEURL}/quiz/updateIsFinishedStatus/?quizId=${Eventid}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          // Add any required parameters here if needed
          // params: { key: 'value' },
        }
      );
      console.log(response.data.success);
      Toast("Updated Succesfully", ToastType.SUCCESS, 1000);
      setUpdateEvent({
        ...updateEvent,
        details: !updateEvent.details,
        questions: !updateEvent.questions,
      });
    } catch (err) {
      console.log(err);
    }
  };
  if (!eventDetails || !questions) {
    return <p>Loading...</p>;
  }

  return (
    <div className="single-event-container">
      <Link to="/events" className="gobacktoAllEvents">
        <BackArrowSVG />
        Back to events
      </Link>
      <div className="single-event-details">
        <div className="single-event-details-inner">
          <div className="single-event-details-left">
            <span className="single-event-title-span">Event</span>
            <span className="single-event-title">
              {eventDetails.Name}
              <span
                className={`${
                  eventDetails.status ? "event-active-span " : ""
                }single-event-status-show`}
              >
                {eventDetails.status ? (
                  <span className="event-active-inner">
                    <span className="active-dot-green"></span>Active
                  </span>
                ) : (
                  <>Inactive</>
                )}
              </span>
            </span>
          </div>
          <div className="single-event-details-right">
            {eventDetails.status ? (
              <StopIcon
                onClick={() =>
                  handleEventStatusChange(eventDetails.id, eventDetails.status)
                }
                datatooltipid="my-tooltip"
                datatooltipcontent="Stop Event"
              />
            ) : (
              <StartIcon
                onClick={() =>
                  handleEventStatusChange(eventDetails.id, eventDetails.status)
                }
                datatooltipid="my-tooltip"
                datatooltipcontent="Start Event"
              />
            )}

            <button
              className={
                eventDetails.isFinished ? "QuestRsultVisible button" : "button"
              }
              onClick={() => handleShowResultForQuiz(eventDetails.id)}
            >
              {eventDetails.isFinished ? "Hide" : "Show"} Result
            </button>
            <button
              onClick={handlePresenterClick}
              className="button presenterBtn"
            >
              Presenter
            </button>
          </div>
        </div>
        <span className="single-event-description">
          {eventDetails.details ? eventDetails.details : null}
        </span>
      </div>
      {/* Add more event details as needed */}
      <div className="questionsListParent">
        <div className="questionsListinnner">
          <div className="questionsListLeft">
            <span className="questionsListLeftTitle">Questions</span>
            {questions.length > 0 &&
              questions.map((question) => (
                <div
                  key={question.id}
                  className="questionsleftsinglequestionparent"
                >
                  <div className="deleteQuestionSvgParent">
                    <DeleteQuestionSVG
                      onClick={() => {
                        setDeleteConfirmationQuestion(question);
                        setShowQuestionDeleteConfirmationModel(true);
                      }}
                      datatooltipid="my-tooltip"
                      datatooltipcontent="Delete Question"
                    />
                  </div>
                  <div
                    className="questionsleftquestion"
                    onClick={() => handleLeftQuestionListSelect(question)}
                  >
                    <span>{question.question}</span>
                  </div>
                  <div className="question-action-btn-group">
                    {question.isActive ? (
                      <StopIcon
                        onClick={() =>
                          handleQuestionStatusChange(id, question.id)
                        }
                        datatooltipid="my-tooltip"
                        datatooltipcontent="Inactive Question"
                      />
                    ) : (
                      <StartIcon
                        onClick={() =>
                          handleQuestionStatusChange(id, question.id)
                        }
                        datatooltipid="my-tooltip"
                        datatooltipcontent="Active Question"
                      />
                    )}
                    <button
                      className={
                        question.showResult
                          ? "QuestRsultVisible button"
                          : "button"
                      }
                      onClick={() => handleQuestResultStatusChange(question.id)}
                    >
                      {question.showResult ? "Hide" : "Show"} Result
                    </button>
                  </div>
                </div>
              ))}
            {questions.length > 0 && (
              <div
                className="questionsleftsinglequestionparent Addquestionsvgparent"
                onClick={() => setAddQuestionComponent(true)}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Add Question"
              >
                <AddQuestionSVG />
              </div>
            )}
            {questions.length === 0 && <p>No questions added.</p>}
          </div>

          <div
            className="questionsListRight
                  "
          >
            {questions.length > 0 && !addQuestionComponent ? (
              <EditQuestion
                setUpdateEvent={setUpdateEvent}
                updateEvent={updateEvent}
                questionId={
                  activeQuestionforEdit ? activeQuestionforEdit.id : null
                }
                preQuestion={
                  activeQuestionforEdit ? activeQuestionforEdit.question : null
                }
                preOptions={
                  activeQuestionforEdit ? activeQuestionforEdit.options : null
                }
              />
            ) : (
              <AddQuestion
                eventId={id}
                setUpdateEvent={setUpdateEvent}
                updateEvent={updateEvent}
                setAddQuestionComponent={setAddQuestionComponent}
              />
            )}
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
      {showQuestionDeleteConfirmationModel ? (
        <QuestionDeleteConfirmation
          isOpen={showQuestionDeleteConfirmationModel}
          onClose={() => setShowQuestionDeleteConfirmationModel(false)}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          question={deleteConfirmationQuestion}
        />
      ) : null}
    </div>
  );
};

export default SingleEvent;
