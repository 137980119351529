import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { projectQuizHandler } from "../../apis/handler/projectQuiz";
import { ResponseStatus } from "../../apis/utils/interfaces/enum";

export const getAllEventAction = createAsyncThunk(
  "FetchDataOfEvent",
  async () => {
    try {
      const res = await projectQuizHandler.getAllQuizAPI();
      if (
        res?.Result?.Response?.Status &&
        res?.Result?.Response?.ResponseStatus === ResponseStatus.Success
      ) {
        return res?.Result?.Response?.Result;
      }
    } catch (err) {
      return err;
    }
  }
);

export const getAllQuizAction = createAsyncThunk(
  "getAllQuizAction",
  async () => {
    try {
      const res = await projectQuizHandler.getAllQuizAPI();
      if (
        res?.Result?.Response?.Status &&
        res?.Result?.Response?.ResponseStatus === ResponseStatus.Success
      ) {
        return res?.Result?.Response?.Result;
      }
    } catch (err) {
      return err;
    }
  }
);
export const toggleDarkMode = () => ({
  type: "TOGGLE_DARK_MODE",
});
