import React, { useState, useEffect } from "react";
import axios from "axios";
import scanner from "../assets/download.svg";
import "../styles/PollResults.css";

const PollResults = () => {
  const [questionData, setQuestionData] = useState(null);
  const [optionPercentages, setOptionPercentages] = useState(null);
  const [totalAttempted, setTotalAttempted] = useState(0);
  const [questionId, setquestionId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isResultShown, setIsResultShown] = useState(false);
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  useEffect(() => {
    // Fetch question data

    const fetchQuestionData = async () => {
      try {
        // Fetch question data
        const questionResponse = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/getAllActiveQuiz`
        );

        let result = questionResponse.data?.response?.result?.data;
        console.log(result);
        if (
          !isEqual(questionData, questionResponse.data?.response?.result?.data)
        ) {
          setQuestionData(questionResponse.data?.response?.result?.data);
          console.log(questionResponse.data?.response?.result?.data?.questions);
          if (questionResponse.data?.response?.result?.data?.questions)
            setquestionId(
              questionResponse.data?.response?.result?.data?.questions[0].id
            );
          setIsResultShown(
            questionResponse.data?.response?.result?.data?.questions[0]
              .showResult
          );
        }

        // Set loading to false after both requests are completed
      } catch (error) {
        console.error("Error fetching data:", error);
        setQuestionData(null);
        setquestionId(null);
        // Set loading to false in case of an error
      }
    };

    fetchQuestionData();
    // Set up polling interval
    const intervalId = setInterval(fetchQuestionData, 2000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchOptionsData = async () => {
      try {
        console.log(questionId);
        // Fetch option percentages
        const optionResponse = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/answer/getSubmittedOptionIdsForQuestion/?questionId=${questionId}`
        );
        console.log(optionResponse.data?.response?.result);
        if (
          !isEqual(
            optionPercentages,
            optionResponse.data?.response?.result?.optionIds
          )
        ) {
          setOptionPercentages(
            optionResponse.data?.response?.result?.optionIds
          );
          setTotalAttempted(
            optionResponse.data?.response?.result?.totalAttempted
          );
        }
        setLoading(false); // Set loading to false after both requests are completed
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };
    if (questionId) {
      fetchOptionsData();
    } else {
      setLoading(false);
    }
    // Set up polling interval
    const intervalId = setInterval(fetchOptionsData, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [optionPercentages, questionId]);

  const renderOptions = () => {
    if (!questionData) {
      return null;
    }

    return questionData.questions[0]?.options?.map((option) => {
      console.log(option);
      const percentage = isResultShown ? optionPercentages[option.id] : 0;

      return (
        <div key={option.id} className="optionParent">
          <p className="pollOptionName">
            {option.optionsName}

            {questionData.questions[0]?.showResult && option.isCorrect ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="15"
                viewBox="0 -960 960 960"
                width="15"
                className="correctpolloptionicon"
                fill="#4caf50"
              >
                <path d="M378-235 142-471l52-52 184 184 388-388 52 52-440 440Z" />
              </svg>
            ) : null}
          </p>
          <div className="poll-progress-bar-container">
            <div
              className="poll-progress-bar"
              style={{
                width: `${percentage ? percentage + "%" : "1rem"}`,
                backgroundColor: `${
                  questionData.questions[0]?.showResult && option.isCorrect
                    ? "#4caf50"
                    : "rgba(255, 255, 255, 0.6)"
                }`,
                borderRadius: "8px",
                color: "#fff",
                textAlign: "center",
              }}
            ></div>
            <span className="percentage-label">
              {percentage ? percentage.toFixed(2) : 0}%
            </span>
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {questionData &&
      questionData.questions &&
      optionPercentages &&
      questionData.questions?.length > 0 ? (
        <div className="pollParent">
          <div className="pollquestionleft">
            <div className="qr-code-container">
              <img className="scannerbigsize" src={scanner} alt="" />
            </div>
            <h3>Scan to</h3>
            <h3>Join the Quiz</h3>
          </div>
          <div className="pollquestionright">
            <div className="pollquestionrightnavbar">
              <div className="activepolltitle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="currentColor"
                >
                  <g fill="none">
                    <path d="M0 0h24v24H0V0z" />
                    <path d="M0 0h24v24H0V0z" opacity=".87" />
                  </g>
                  <path d="M4 13c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1zm-3 5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm4 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 8c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z" />
                </svg>
                <p>Active Poll</p>
              </div>
              <div className="pollusers">
                <p>{totalAttempted ? totalAttempted : 0}</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="currentColor"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5z" />
                </svg>
              </div>
            </div>
            <div className="pollquestionparent">
              <span className="pollquestionName">
                {questionData.questions[0]?.question}
              </span>
              {renderOptions()}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="noactivepollcontainer">
            <h2 className="noactivepollheading">Poll</h2>
            <div className="noactivepollinner">
              <div className="nopollleft">
                <h1>Scan to </h1>
                <h1>Join the Quiz</h1>
              </div>
              <div className="nopollright">
                <div className="qr-code-container">
                  <img className="scannerbigsize" src={scanner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PollResults;
