import React, { useEffect, useState } from "react";

import "../../styles/UserEventLayout.css";
import correctIcon from "../../assets/check_black_24dp.svg";
import wrongIcon from "../../assets/clear_black_24dp.svg";

import { useNavigate } from "react-router-dom";
import { AnnouncementSVG, isValidUser } from "../../utils";
import { useSelector } from "react-redux";
import axios from "axios";
import QuizResults from "./QuizResults";

export default function CreateEventLayout() {
  const [quizData, setQuizData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timer, setTimer] = useState();
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const [showResultButtonText, setResultButtonText] = useState("");
  const token = useSelector((state) => state.user?.token);
  const user = useSelector((state) => state.user);
  const [showEventResult, setEventResult] = useState(false);
  const [userResults, setUserResults] = useState();
  const [summary, setSummary] = useState(null);
  const [questionsWithOptions, setQuestionsWithOptions] = useState();

  const [announcementMsg, setannouncementMsg] = useState("");
  const [singleEventDetailsForResult, setSingleEventDetailsForResult] =
    useState();
  const [isResultShown, setIsResultShown] = useState(false);
  // if (!isValidUser()) navigate("/home");

  const renderAnswerStatus = (optionId, question) => {
    const userId = user ? user.profile?.id : null;
    const storedAnswers =
      JSON.parse(localStorage.getItem("selectedAnswers")) || {};

    const userAnswer = storedAnswers[userId]?.[quizData.id]?.[question.id];
    const correctOptionId = question.options.find(
      (option) => option.isCorrect === 1
    )?.id;

    if (question.showResult === 1) {
      if (userAnswer === correctOptionId && optionId === correctOptionId) {
        return "correct selected";
      }

      if (userAnswer === optionId) {
        return "wrong";
      }

      if (optionId === correctOptionId) {
        return "correct";
      }
      if (!userAnswer) {
        return "Not Attempted";
      }
    }

    return "";
  };

  const fetchData = async () => {
    try {
      console.log(process.env.REACT_APP_API_BASEURL);
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/quiz/getAllActiveQuiz`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          // Add any required parameters here if needed
          // params: { key: 'value' },
        }
      );
      const data = response.data?.response?.result?.data;
      if (data?.isFinished) {
        setSingleEventDetailsForResult(data);
        const userId = user ? user.profile?.id : null;

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/getUserResult/?userId=${userId}&quizId=${data.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            // Add any required parameters here if needed
            // params: { key: 'value' },
          }
        );
        console.log(response.data?.response?.result?.userResults);
        setUserResults(response.data?.response?.result?.userResults);
        const allQuestionsWithOption = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/questions/getAllQuestionsWithOptionsByEventId?eventId=${data.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            // Add any required parameters here if needed
            // params: { key: 'value' },
          }
        );
        setQuestionsWithOptions(
          allQuestionsWithOption.data?.response?.result?.questionsWithOptions
        );
        // Calculate summary
        const correctCount =
          response.data?.response?.result?.userResults.filter(
            (result) => result.correctness === "correct"
          ).length;
        const wrongCount = response.data?.response?.result?.userResults.filter(
          (result) => result.correctness === "wrong"
        ).length;

        setSummary({ correctCount, wrongCount });
        setEventResult(true);
      } else {
        setEventResult(false);
        setIsResultShown(data ? data.questions[0]?.showResult : false);
        // Only update the state if the data has changed
        if (!isEqual(quizData, data)) {
          setResultButtonText("");
          setIsAnswerSubmitted(false);
          setIsTimerCompleted(false);
          setQuizData(data);
          setSelectedOption(null); // Reset selected option on each fetch
          console.log(data.questions.length);
          if (data.questions.length > 0) {
            const startTimestamp = new Date().getTime();
            localStorage.setItem("timerStart", startTimestamp);
            setTimer(30); // Reset the timer on each fetch
          }
        }
      } // Reset the timer on each fetch
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (isResultShown) {
      const userId = user ? user.profile?.id : null;
      const storedAnswers =
        JSON.parse(localStorage.getItem("selectedAnswers")) || {};
      const question = quizData.questions[0];
      const userAnswer = storedAnswers[userId]?.[quizData.id]?.[question.id];

      const correctOptionId = question.options.find(
        (option) => option.isCorrect === 1
      )?.id;

      if (question.showResult === 1) {
        if (userAnswer === correctOptionId) {
          setResultButtonText("Correct");
        }

        if (userAnswer && userAnswer !== correctOptionId) {
          setResultButtonText("Wrong");
        }
        if (!userAnswer) {
          setResultButtonText("Not Attempted");
        }
      }
    }
  }, [isResultShown]);
  const isEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5000); // Fetch data every 5 seconds
    return () => clearInterval(interval);
  }, [quizData]); // Add quizData as a dependency

  //fetch active announcement every 5 seconds
  const fetchAnnouncement = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASEURL}/announcement/getActiveAnnouncement`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          // Add any required parameters here if needed
          // params: { key: 'value' },
        }
      );
      console.log("announcement", response.data?.response?.result);
      const data = response.data?.response?.result;
      if (data && data.length > 0) {
        setannouncementMsg(data[0].message);
      } else {
        setannouncementMsg("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchAnnouncement();
    const interval = setInterval(fetchAnnouncement, 5000); // Fetch data every 5 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let timerInterval;
    if (timer > 0) {
      timerInterval = setInterval(
        () => setTimer((prevTimer) => prevTimer - 1),
        1000
      );
    } else {
      // Timer has reached 0, disable the button or perform any other action
      clearInterval(timerInterval);
      setIsTimerCompleted(true); // Set timer completion flag
    }

    return () => clearInterval(timerInterval);
  }, [timer]);

  const handleOptionChange = (event) => {
    console.log("Selected option:", event.target.value);
    setSelectedOption(parseInt(event.target.value));
  };

  const getUserIdFromLocalStorage = () => {
    const userInfo = localStorage.getItem("UserInfo");

    if (userInfo) {
      try {
        const profile = JSON.parse(userInfo);
        return profile.id;
      } catch (error) {
        console.error("Error parsing user info:", error);
      }
    }

    return null;
  };

  // Example function to store selected answer in local storage
  const storeSelectedAnswer = (userId, quizId, questionId, selectedOption) => {
    const storedAnswers =
      JSON.parse(localStorage.getItem("selectedAnswers")) || {};

    storedAnswers[userId] = storedAnswers[userId] || {};
    storedAnswers[userId][quizId] = storedAnswers[userId][quizId] || {};
    storedAnswers[userId][quizId][questionId] = selectedOption;

    localStorage.setItem("selectedAnswers", JSON.stringify(storedAnswers));
  };

  // Example function to retrieve selected answer from local storage
  const getSelectedAnswer = (userId, quizId, questionId) => {
    const storedAnswers =
      JSON.parse(localStorage.getItem("selectedAnswers")) || {};

    return storedAnswers[userId]?.[quizId]?.[questionId] || null;
  };

  // Example function to clear selected answers from local storage
  const clearSelectedAnswers = () => {
    localStorage.removeItem("selectedAnswers");
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const userId = user.profile?.id;
    const { questions, id: quizId } = quizData;
    const question = questions[0];
    const questionId = question.id;

    const body = {
      userId,
      questionId,
      quizId,
      optionId: parseInt(selectedOption, 10),
    };

    const authToken = localStorage.getItem("token");
    console.log(body, authToken);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}/answer/createAnswer`,
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Accept-Language": "en-US,en;q=0.9",
            Authorization: `Bearer ${authToken}`,
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            Origin: "http://srv461269.hstgr.cloud:3000",
            Pragma: "no-cache",
            Referer: "http://srv461269.hstgr.cloud:3000/",
            "User-Agent":
              "Mozilla/5.0 (iPhone; CPU iPhone OS 16_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.6 Mobile/15E148 Safari/604.1",
          },
          body: JSON.stringify(body),
        }
      );

      // Handle the response as needed
      const data = await response.json();
      console.log("Answer submission response:", data);
      if (data.success) {
        setIsAnswerSubmitted(true);
        localStorage.removeItem("timerStart");
        storeSelectedAnswer(
          userId,
          quizId,
          questionId,
          parseInt(selectedOption)
        );
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };
  if (showEventResult) {
    return (
      <QuizResults
        userResults={userResults}
        questionsWithOptions={questionsWithOptions}
        summary={summary}
        singleEventDetailsForResult={singleEventDetailsForResult}
        announcementMsg={announcementMsg}
      />
    );
  }
  if (!quizData || quizData === null) {
    return (
      <>
        {announcementMsg ? (
          <>
            <div className="live-event-with-question announcement-parent">
              <span className=" client-announcement-title">
                <AnnouncementSVG /> Announcement
              </span>
              <p className="client-announcement">{announcementMsg}</p>
            </div>
          </>
        ) : null}
        <h2 className="event-tag">Event</h2>
        <div className="live-event-with-question ">
          <div className="not-live">
            <p>No active event at the moment</p>
          </div>
        </div>
      </>
    );
  }

  const { questions, Name, status } = quizData;

  if (status === 0) {
    return (
      <div className="not-live">
        <p>Event is not live yet</p>
        <p>Event Name: {Name}</p>
      </div>
    );
  } else if (questions.length === 0) {
    return (
      <>
        {announcementMsg ? (
          <>
            <div className="live-event-with-question announcement-parent">
              <span className=" client-announcement-title">
                <AnnouncementSVG /> Announcement
              </span>
              <p className="client-announcement">{announcementMsg}</p>
            </div>
          </>
        ) : null}
        <h2 className="event-tag">Event</h2>
        <div className="event-title">
          <h1>{Name}</h1>
        </div>
        <div className="live-event-with-question">
          <p className="no-questions-live">
            Currently, there are no questions available.
          </p>
          <p className="no-questions-live">
            Please be patient; questions will be available shortly.
          </p>
        </div>
      </>
    );
  } else {
    const question = questions[0];
    return (
      <>
        {announcementMsg ? (
          <>
            <div className="live-event-with-question announcement-parent">
              <span className=" client-announcement-title">
                <AnnouncementSVG /> Announcement
              </span>
              <p className="client-announcement">{announcementMsg}</p>
            </div>
          </>
        ) : null}

        <h2 className="event-tag">Event</h2>
        <div className="event-title">
          <h1>{Name}</h1>
        </div>
        <div className="live-event-with-question">
          <h3>{question.question}</h3>
          <form onSubmit={handleFormSubmit}>
            <ul>
              {question.options.map((option) => (
                <li
                  key={option.id}
                  className={`options-li ${
                    selectedOption === option.id ? "selected-option" : ""
                  } ${question.showResult === 1 ? "show-result" : ""} ${
                    question.showResult === 1 &&
                    renderAnswerStatus(option.id, question)
                  }`}
                  id={timer === 0 ? "nohover" : null}
                  onClick={() => {
                    // Trigger the click on the hidden radio input
                    const radioInput = document.getElementById(
                      `option-${option.id}`
                    );
                    if (radioInput) {
                      radioInput.click();
                    }
                  }}
                >
                  <label>
                    <input
                      type="radio"
                      id={`option-${option.id}`}
                      name="option"
                      value={option.id}
                      onChange={handleOptionChange}
                      disabled={timer === 0}
                    />
                    {option.optionsName}
                  </label>
                  {renderAnswerStatus(option.id, question) ===
                    "correct selected" ||
                  renderAnswerStatus(option.id, question) === "correct" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="currentColor"
                        className="icon-image"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z" />
                      </svg>
                    </>
                  ) : renderAnswerStatus(option.id, question) === "wrong" ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#ffffff"
                      className="icon-image"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
                    </svg>
                  ) : null}
                </li>
              ))}
            </ul>
            {!isAnswerSubmitted && question.showResult !== 1 && (
              <>
                <p className="timer-seconds">Time left: {timer} seconds</p>

                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${((30 - timer) / 30) * 100}%` }}
                  ></div>
                </div>
              </>
            )}
            <div className="submit-btn-container">
              <button
                type="submit"
                disabled={
                  timer === 0 ||
                  quizData?.questions.some(
                    (question) => question.showResult === 1
                  ) ||
                  isAnswerSubmitted
                }
                className={
                  isAnswerSubmitted ? "submitted-btn submit-btn" : "submit-btn"
                }
                id={
                  showResultButtonText && showResultButtonText === "Correct"
                    ? "correctBtn"
                    : showResultButtonText === "Wrong"
                    ? "wrongBtn"
                    : ""
                }
              >
                {question.showResult !== 1
                  ? isAnswerSubmitted
                    ? "Submitted"
                    : isTimerCompleted
                    ? "Not submitted"
                    : "Submit"
                  : null}
                {question.showResult === 1 && showResultButtonText}
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  //   return (
  //     <ThemeProvider theme={darkTheme}>
  //       <Box bgcolor={"background.default"} color={"text.primary"}>
  //         <Navbar />
  //         <Stack direction="row" spacing={2} justifyContent="space-between">
  //           <DashboardSidebar setMode={setMode} mode={mode} />

  //           <EventListRender />
  //         </Stack>
  //         <Add />
  //       </Box>
  //     </ThemeProvider>
  //   );
}
