export class requestModel {
    url;
    method;
    params;
    data;
    headers;
    timeout;
    withCredentials;
    auth;
    responseType;
    responseEncoding;
    xsrfCookieName;
    xsrfHeaderName;
    maxContentLength;
    maxBodyLength;
    proxy;
}
