import React, { useEffect, useState } from "react";
import UserLogin from "../../components/user/UserLogin";
import { useSelector } from "react-redux";
import CreateEventLayout from "../../components/user/UserEventLayout";

function User() {
  const [isValid, setIsValid] = useState(true);
  const user = useSelector((state) => state.user);
  async function fetchToken() {
    const token = user?.token;
    setIsValid(!!token);
    console.log("token", token, !!token);
  }
  useEffect(() => {
    fetchToken();
  }, [user]);

  return (
    <div>
      {isValid ? (
        <>
          <CreateEventLayout />
        </>
      ) : (
        <UserLogin />
      )}
    </div>
  );
}

export default User;
