// QuestionModal.js
import React, { useState } from "react";
import Modal from "react-modal";
import CustomModal from "../Modal";
import axios from "axios";
import { useSelector } from "react-redux";
import { DeleteOption, IsCorrectOption, Toast, ToastType } from "../../utils";
import "../../styles/AddQuestionComponent.css";

const AddQuestion = ({
  eventId,
  setUpdateEvent,
  updateEvent,
  setAddQuestionComponent,
}) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([
    { optionsName: "", isCorrect: false },
    { optionsName: "", isCorrect: false },
  ]);
  const token = useSelector((state) => state.user?.token);

  const handleAddOption = () => {
    setOptions([...options, { optionsName: "", isCorrect: false }]);
  };

  const handleOptionChange = (index, field, value) => {
    console.log(index, field, value);
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  const handleDeleteOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleSubmit = () => {
    const hasCorrectOption = options.some((option) => option.isCorrect);

    if (!hasCorrectOption) {
      Toast("Please select a correct option ", ToastType.ERROR, 2000);
      return;
    }
    const formData = {
      question: question,
      options: options.map((option) => ({
        optionsName: option.optionsName,
        isCorrect: option.isCorrect,
      })),
      quizId: eventId, // Replace this with the actual quizId
    };

    console.log(formData);

    // API call to submit the question with options
    axios
      .post(
        `${process.env.REACT_APP_API_BASEURL}/questions/CreateQuestionWithOption/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Replace with your actual access token
          },
        }
      )
      .then((response) => {
        Toast("Question added Succesfully", ToastType.SUCCESS, 2000);

        console.log(JSON.stringify(response.data));
        setAddQuestionComponent(false);
        setUpdateEvent({ ...updateEvent, questions: !updateEvent.questions });
      })
      .catch((error) => {
        console.log(error);
      });
    // Close the modal
    // onClose();
  };

  return (
    <div className="add-question-main">
      <span className="main-heading">Add Question</span>
      <div className="add-question-input-parent">
        <label className="form-label" htmlFor="question">
          Question:
        </label>
        <input
          type="text"
          id="question"
          className="form-input"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </div>
      {options.map((option, index) => (
        <div key={index} className="option-container">
          <div className="select-correct-option">
            <IsCorrectOption
              id={`correct-${index}`}
              className={option.isCorrect ? "selected" : ""}
              checked={option.isCorrect}
              onClick={(e) =>
                handleOptionChange(
                  index,
                  "isCorrect",
                  option.isCorrect ? false : true
                )
              }
            />
          </div>
          <div className="options-input-parent">
            <label className="form-label" htmlFor={`option-${index}`}>
              {`Option ${index + 1}:`}
            </label>
            <input
              type="text"
              id={`option-${index}`}
              className="form-input"
              value={option.optionsName}
              onChange={(e) =>
                handleOptionChange(index, "optionsName", e.target.value)
              }
            />
          </div>
          <DeleteOption onClick={() => handleDeleteOption(index)} />
        </div>
      ))}
      <div className="button-group">
        <button className="button" onClick={handleAddOption}>
          Add Option
        </button>
        <button className="button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddQuestion;
