import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../styles/EventResultForAdmin.css";
import { useSelector } from "react-redux";
import { BackArrowSVG, SortingIconSVG } from "../../utils";

function EventResultForAdmin() {
  const { id } = useParams();
  console.log(id ? id : null);
  const [eventDetails, setEventDetails] = useState([]);
  const [quizStatsBasedOnQuests, setquizStatsBasedOnQuests] = useState([]);
  const [quizStatsBasedOnUsers, setquizStatsBasedOnUsers] = useState([]);
  const [activeTab, setActiveTab] = useState("question");
  const token = useSelector((state) => state.user?.token);

  useEffect(() => {
    const fetchQuestionsData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/quizStatsBasedOnQuests?quizId=${id}`
        );
        const data = response.data.response?.result?.questionStatistics;
        console.log(data);
        setquizStatsBasedOnQuests(data);
      } catch (error) {
        console.error("Error fetching questions data:", error);
      }
    };
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/getQuizById?id=${id}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(response.data.response.result.events);
        // Assuming response.data.Result contains event details
        setEventDetails(response.data.response.result.events);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };
    const fetchUsersData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/quiz/quizStatsBasedOnUsers?quizId=${id}`
        );
        const data = response.data.response?.result?.userList;
        console.log(data);
        setquizStatsBasedOnUsers(data);
      } catch (error) {
        console.error("Error fetching questions data:", error);
      }
    };

    fetchEventData();
    // Call the functions to fetch data
    fetchUsersData();
    fetchQuestionsData();
  }, [id, token]);

  //sorting algo for questions based table
  const [sortedColumn, setSortedColumn] = useState("numberOfAttempts");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSort = (column) => {
    if (sortedColumn === column) {
      // If the same column is clicked again, reverse the sorting direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sorted column and set the sorting direction to ascending
      setSortedColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedData = [...quizStatsBasedOnQuests].sort((a, b) => {
    if (sortedColumn === "numberOfAttempts") {
      return sortDirection === "asc"
        ? a.numberOfAttempts - b.numberOfAttempts
        : b.numberOfAttempts - a.numberOfAttempts;
    } else if (sortedColumn === "correctAnswerPercentage") {
      return sortDirection === "asc"
        ? a.correctAnswerPercentage - b.correctAnswerPercentage
        : b.correctAnswerPercentage - a.correctAnswerPercentage;
    } else {
      // For other columns, maintain the current order
      return 0;
    }
  });

  //sorting algo for user based table
  const [sortedColumnUser, setSortedColumnUser] = useState(
    "numberOfCorrectAnswers"
  );
  const [sortDirectionUser, setSortDirectionUser] = useState("desc");

  const handleSortUser = (column) => {
    if (sortedColumnUser === column) {
      // If the same column iUsers clicked again, reverse the sorting direction
      setSortDirectionUser(sortDirectionUser === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sorted column and set the sorting direction to ascending
      setSortedColumnUser(column);
      setSortDirectionUser("asc");
    }
  };

  const sortedDataUser = [...quizStatsBasedOnUsers].sort((a, b) => {
    if (sortedColumnUser === "numberOfQuestionsAttempted") {
      return sortDirectionUser === "asc"
        ? a.numberOfQuestionsAttempted - b.numberOfQuestionsAttempted
        : b.numberOfQuestionsAttempted - a.numberOfQuestionsAttempted;
    } else if (sortedColumnUser === "numberOfCorrectAnswers") {
      return sortDirectionUser === "asc"
        ? a.numberOfCorrectAnswers - b.numberOfCorrectAnswers
        : b.numberOfCorrectAnswers - a.numberOfCorrectAnswers;
    } else if (sortedColumnUser === "correctAnswerPercentage") {
      const aPercentage =
        (a.numberOfCorrectAnswers / a.numberOfQuestionsAttempted) * 100;
      const bPercentage =
        (b.numberOfCorrectAnswers / b.numberOfQuestionsAttempted) * 100;
      return sortDirectionUser === "asc"
        ? aPercentage - bPercentage
        : bPercentage - aPercentage;
    } else {
      // For other columns, maintain the current order
      return 0;
    }
  });

  return (
    <div className="single-event-container margin-top">
      <Link to="/events" className="gobacktoAllEvents">
        <BackArrowSVG />
        Back to events
      </Link>
      <h2 className="">Event Result Statistics</h2>
      <div className="single-event-details">
        <div className="single-event-details-inner">
          <div className="single-event-details-left">
            <span className="single-event-title">
              {eventDetails?.Name}
              <span
                className={`${
                  eventDetails?.status ? "event-active-span " : ""
                }single-event-status-show`}
              >
                {eventDetails?.status ? (
                  <span className="event-active-inner">
                    <span className="active-dot-green"></span>Active
                  </span>
                ) : (
                  <>Inactive</>
                )}
              </span>
            </span>
          </div>
        </div>
        <span className="single-event-description">
          {eventDetails?.details ? eventDetails.details : null}
        </span>
      </div>

      <div className="tab-container margin-top">
        <div className="tabs">
          <button
            onClick={() => setActiveTab("question")}
            className={activeTab === "question" ? "active" : ""}
          >
            Question Based
          </button>
          <button
            onClick={() => setActiveTab("user")}
            className={activeTab === "user" ? "active" : ""}
          >
            User Based
          </button>
        </div>
        <div className="tab-content">
          {activeTab === "question" ? (
            <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Question</th>
                    <th onClick={() => handleSort("numberOfAttempts")}>
                      <div className="sortingParent">
                        Number of Attempts
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th onClick={() => handleSort("correctAnswerPercentage")}>
                      <div className="sortingParent">
                        Correct Answer Percentage
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData.map((item, key) => (
                    <tr key={item.questionId}>
                      <td>{key + 1}</td>
                      <td>{item.question}</td>
                      <td>{item.numberOfAttempts}</td>
                      <td>{item.correctAnswerPercentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="table-container">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User ID</th>
                    <th>User Name</th>
                    <th>Mobile</th>
                    <th
                      onClick={() =>
                        handleSortUser("numberOfQuestionsAttempted")
                      }
                    >
                      <div className="sortingParent">
                        Number of Questions Attempted
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th
                      onClick={() => handleSortUser("numberOfCorrectAnswers")}
                    >
                      <div className="sortingParent">
                        Number of Correct Answers
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                    <th
                      onClick={() => handleSortUser("correctAnswerPercentage")}
                    >
                      <div className="sortingParent">
                        Correct Answer Percentage
                        <span>
                          <SortingIconSVG />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedDataUser.map((item, key) => (
                    <tr key={item.UserId}>
                      <td>{key + 1}</td>
                      <td>{item.UserId}</td>
                      <td>{item.Username}</td>
                      <td>{item.mobile}</td>
                      <td>{item.numberOfQuestionsAttempted}</td>
                      <td>{item.numberOfCorrectAnswers}</td>
                      <td>
                        {(
                          (item.numberOfCorrectAnswers /
                            item.numberOfQuestionsAttempted) *
                          100
                        ).toFixed(2)}
                        %
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventResultForAdmin;
