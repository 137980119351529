import React, { useEffect, useState } from "react";
import { authHandler } from "../apis/handler/auth";
import { useNavigate } from "react-router-dom";
import { ResponseStatus } from "../apis/utils/interfaces/enum";
import { Toast, ToastType } from "../utils";
import { useDispatch } from "react-redux";
import "../styles/AdminLogin.css";

function AdminLogin(props) {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  // form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"), // Keep this line if you want to print the password (for debugging), but do not send it to the API.
    });

    const res = await authHandler.loginAPI({
      email: data.get("email"),
      password: data.get("password"), // Include the password if your API requires it.
    });

    console.log("res", res);

    if (
      res?.Result?.response?.status &&
      res?.Result?.response?.responseStatus === ResponseStatus.Success
    ) {
      dispatch({
        type: "SET_USER",
        payload: res?.Result?.response?.result?.data,
      });
      localStorage.setItem(
        "UserInfo",
        JSON.stringify(res?.Result?.response?.result?.data)
      );
      localStorage.setItem(
        "token",
        JSON.stringify(res?.Result?.response?.result?.data?.token)
      );
      Toast("Login  successful  ", ToastType.SUCCESS, 5000);
      props.setIsLoggedIn(true);
    } else if (!res?.Result?.response?.status) {
      Toast(res?.Result?.response?.message, ToastType.ERROR, 5000);
    }
  };

  return (
    <div className="login-container">
      <h2 className="profile-heading">Admin Login</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button className="button" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}

export default AdminLogin;
