// store.js
import { configureStore } from "@reduxjs/toolkit";

const userInfo = JSON.parse(localStorage.getItem("UserInfo")) || null;
const userData = {
  profile: userInfo?.profile,
  token: JSON.parse(localStorage.getItem("token")) || null,
  isDarkMode: JSON.parse(localStorage.getItem("IsDarkMode")),
};
const initialState = userData;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        profile: action.payload.profile,
        token: action.payload.token,
      };
    case "TOGGLE_DARK_MODE":
      return { ...state, isDarkMode: !userData.isDarkMode };
    case "LOGOUT":
      return null;
    default:
      return state.profile ? state : null;
  }
};

const store = configureStore({
  reducer: { user: userReducer },
});

export default store;
