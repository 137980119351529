import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const CustomModal = ({ isOpen, onRequestClose, children }) => {
  const [mode, setMode] = useState("light");
  const customStyles = {
    content: {
      top: "10%",
      width: "80%",
      maxWidth: "700px",
      maxHeight: "max-content",
      margin: "0 auto",
      backgroundColor: mode === "dark" ? "#1e1e1e" : "#ffffff",
    },
  };
  useEffect(() => {
    if (document.body.classList.contains("dark-mode")) {
      setMode("dark");
    } else if (document.body.classList.contains("light-mode")) {
      setMode("light");
    }
    // Cleanup function to remove the class when the component unmounts
    return () => {
      setMode("");
    };
  }, [mode]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false} // This is required to prevent a warning
    >
      <div className="modal-close">
        <button className="button inline" onClick={onRequestClose}>
          Close
        </button>
      </div>
      {children}
    </Modal>
  );
};

export default CustomModal;
