import { GET, POST } from "../../utils/Requests";

import { userAuthEndPoints } from "../../endpoints";

export const authHandler = {
  loginAPI,
  signUpAppUserAPI,
  appLoginAPI,
};
// async function registerAPI(object: any) {
//     try {
//         return await POST(userAuthEndPoints.REGISTER_USER, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('registerAPI'), error);
//     }
// }
//
// async function checkIfEmailAlreadyRegisteredAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.CHECK_EMAIL_ALREADY_REGISTERED, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('checkIfEmailAlreadyRegisteredAPI'), error);
//     }
// }

async function loginAPI(object) {
  try {
    return await POST(userAuthEndPoints.LOGIN, object);
  } catch (error) {
    console.log("login", error);
  }
}
async function appLoginAPI(object) {
  try {
    return await POST(userAuthEndPoints.APP_USER_SIGNIN, object);
  } catch (error) {
    console.log("login", error);
  }
}
async function signUpAppUserAPI(object) {
  try {
    return await POST(userAuthEndPoints.APP_USER_SIGNUP, object);
  } catch (error) {
    console.log("login", error);
  }
}
// async function logoutAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.LOGOUT, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('loginAPI'), error);
//     }
// }
// async function ResendEmailConfirmationLinkAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.RESEND_EMAIL_CONFIRM_URL, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('ResendEmailConfirmationLinkAPI'), error);
//     }
// }

// async function ForgotPasswordAPI(object: any) {
//     try {
//         return await POST(AddQueryStringToEndPoint(userAuthEndPoints.FORGOT_PASSWORD, object));
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('ForgotPasswordAPI'), error);
//     }
// }
// async function resetPasswordAPI(object: any) {
//     try {
//         const responseOBJ = new commanResponse();
//
//         //schema for payload
//         const resetPasswordSchema = Yup.object({
//             token: Yup.string().required(CONFIG.MESSAGE.REQUIRED_TOKEN),
//             email: Yup.string().email(CONFIG.MESSAGE.INVALID_EMAIL).required(),
//             password: Yup.string().required(CONFIG.MESSAGE.PASSWORD_REQUIRE),
//             confirmPassword: Yup.string().required(CONFIG.MESSAGE.CONFIRM_PASSWORD_REQUIRE)
//         });
//
//         if (await resetPasswordSchema.isValid(object)) return await POST(userAuthEndPoints.RESET_PASSWORD, object);
//         else {
//             responseOBJ.ResponseStatus = ResponseStatus.UnprocessableEntity;
//             responseOBJ.Status = false;
//             responseOBJ.Message = await resetPasswordSchema.validate(object).catch((e: any) => e.message);
//         }
//         return responseOBJ;
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('resetPasswordAPI'), error);
//     }
// }
