export { userAuthEndPoints, QuizEndpoint };

const userAuthEndPoints = {
  REGISTER_USER: `/auth/login`,
  LOGIN: `/auth/login`,
  APP_USER_SIGNUP: "/appUser/signup",
  APP_USER_SIGNIN: "/appUser/login",
};
const QuizEndpoint = {
  getQuizNameByid: "/quiz/getQuizNameByid",
  GET_ALL_QUIZ: "/quiz/getAllQuiz",
  UPDATE_VISIBILITY: "/questions/updateVisibility",
  GET_QUIZ_BY_ID: "/quiz/getQuiz",
  CREATE_QUIZ: "/quiz/createQuiz",
  GET_ALL_EVENT: "/event/getAllEvents",
  CREATE_EVENT: "/event/createEvent",
  GETALL_QUIZ: "/quiz/getAllQuiz",
  GET_ALL_QUE: "/questions/getAllQuestion",
  CREATE_QUE: "/questions/createQuestions",
  GET_QUE_BY_ID: "/questions/getQueById",
  ADD_NEW_OPTION: "/option/addNewOption",
  GET_ACTIVE_QUE: "/quiz/getActiveQue",
  CREATE_ANSWER: "/answer/createAnswer",
  GET_ACTIVE_RESULT: "/quiz/getActiveResult",
  generateQuizResult: "/quiz/generateQuizResult",
  GET_QUIZ_NAVIGATE_URL: "/quiz/getQuizNevigateUrl",
  UPDATE_RESULT_STATUS: "/questions/updateResultStatus",
  CreateQuecationWithOption: "/questions/CreateQuecationWithOption",
};
