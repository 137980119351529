import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Toast, ToastType } from "../../utils";
import EventGrid from "../../components/EventGrid";
import CreateEvent from "../../components/quiz/CreateEvent";
import AnnouncementGrid from "../../components/AnnouncementGrid";
import CreateAnnouncement from "../../components/announcements/CreateAnnouncement";

function Announcements() {
  const token = useSelector((state) => state.user?.token);
  const user = useSelector((state) => state.user);
  const [addAnnouncementModalOpen, setaddAnnouncementModalOpen] =
    useState(false);

  const [updateEvent, setUpdateEvent] = useState(true);
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingForCopyingEvent, setLoadingForCopyingEvent] = useState({
    status: false,
    index: -1,
  });
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [announcements, setAnnouncements] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/announcement/getAllAnnouncements`,
          {
            params: "",
            headers: {
              Authorization: token,
              // Add any other headers if needed
            },
          }
        );
        console.log(response.data?.response?.result);
        setEventData(response.data?.response?.result);
        // setEventData([]);
        setError(null);
      } catch (error) {
        console.log(error);
        if (
          error.response?.data === "Unauthorized" &&
          error.response?.status === 401
        ) {
          localStorage.removeItem("UserInfo");
          localStorage.removeItem("token");
          dispatch({ type: "LOGOUT" });
        }
        setEventData(null);
        setError(error.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [token, updateEvent]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (eventData) {
      // Assuming your API response has a 'createdAt' field for the event time
      const eventsWithLocalTime = eventData.map((event) => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const options = {
          timeZone: userTimeZone,
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true, // Use 12-hour format
        };
        const localTimeString = new Date(event.createdAt).toLocaleString(
          "en-US",
          options
        );

        // Add the localTimeString to the event object
        return { ...event, localTimeString };
      });

      setAnnouncements(eventsWithLocalTime);
    }
  }, [eventData]);

  const handleAnnouncementStatusChange = async (id, status) => {
    setLoading({ ...loading, updateEventStatus: true });
    try {
      const updateStatus = status ? 0 : 1;
      const apiUrl = `${process.env.REACT_APP_API_BASEURL}/announcement/changeStatus?id=${id}&status=${updateStatus}`;
      console.log(token);
      const response = await axios.post(apiUrl, {
        headers: {
          Authorization: token,
        },
      });

      console.log(response.data);
      setLoading({ ...loading, updateEventStatus: false });
      Toast("Updated Succesfully", ToastType.SUCCESS, 1000);
      setUpdateEvent({
        ...updateEvent,
        details: !updateEvent.details,
        questions: !updateEvent.questions,
      });
      // Handle success, update your component state, show a message, etc.
    } catch (error) {
      console.error(error);
      // Handle error, show an error message, etc.
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  if (eventData.length > 0)
    return (
      <div className="events-list-container">
        <h2>Announcements</h2>
        <div className="event-add-btn">
          <button
            className="button"
            onClick={() => setaddAnnouncementModalOpen(true)}
          >
            Add Announcement
          </button>
        </div>
        <AnnouncementGrid
          events={announcements}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          handleAnnouncementStatusChange={handleAnnouncementStatusChange}
        />
        {addAnnouncementModalOpen ? (
          <CreateAnnouncement
            isOpen={addAnnouncementModalOpen}
            onClose={() => setaddAnnouncementModalOpen(false)}
            setUpdateEvent={setUpdateEvent}
            updateEvent={updateEvent}
          />
        ) : null}
      </div>
    );
  else
    return (
      <div className="events-list-container">
        <h2>Announcements</h2>
        <div className="event-add-btn">
          <button
            className="button"
            onClick={() => setaddAnnouncementModalOpen(true)}
          >
            Add Announcement
          </button>
        </div>
        {/* <AnnouncementGrid events={[]} /> */}
        {addAnnouncementModalOpen ? (
          <CreateAnnouncement
            isOpen={addAnnouncementModalOpen}
            onClose={() => setaddAnnouncementModalOpen(false)}
            setUpdateEvent={setUpdateEvent}
            updateEvent={updateEvent}
          />
        ) : null}
      </div>
    );
}

export default Announcements;
