import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastErrorSvg, ToastSuccessSvg } from "./utils";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="toast-container-custom"
        toastOptions={{
          success: {
            icon: (
              <div className="toast-container-div eVC">
                <ToastSuccessSvg />
              </div>
            ),
            style: {
              backgroundColor: "#009049",
              color: "#fff",
              fontSize: "16px",
              zIndex: "1000000",
            },
          },
          error: {
            icon: (
              <div className="toast-container-div">
                <ToastErrorSvg />
              </div>
            ),
            style: {
              backgroundColor: "red",
              color: "#fff",
              fontSize: "16px",
            },
          },
        }}
      />
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
