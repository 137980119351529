import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import EditEvent from "./quiz/EditEvent";
import EventDeleteConfirmation from "./quiz/EventDeleteConfirmation";
import { StartIcon, StopIcon } from "../utils";
import EditAnnouncement from "./announcements/EditAnnouncement";
import AnnouncementDeleteConfirmation from "./announcements/AnnouncementDeleteConfirmation";

const AnnouncementCard = ({
  event,
  index,
  seteditEventIndex,
  setEditEventModalOpen,
  setDeleteEventConfirmationMsg,
  setdeleteEventIndex,
  handleAnnouncementStatusChange,
}) => {
  console.log(event);
  console.log(event.message);
  return (
    <div className="event-card announcement" key={index}>
      <div className="eventCopyIcon">
        {event.active ? (
          <StopIcon
            onClick={() =>
              handleAnnouncementStatusChange(event.id, event.active)
            }
            datatooltipid="my-tooltip"
            datatooltipcontent="Stop Event"
          />
        ) : (
          <StartIcon
            onClick={() =>
              handleAnnouncementStatusChange(event.id, event.active)
            }
            datatooltipid="my-tooltip"
            datatooltipcontent="Start Event"
          />
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
          onClick={() => {
            seteditEventIndex(index);
            setEditEventModalOpen(true);
          }}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
          onClick={() => {
            setDeleteEventConfirmationMsg(true);
            setdeleteEventIndex(index);
          }}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <path d="M6,19c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V7H6V19z M9.17,12.59c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0 L12,12.59l1.41-1.41c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L13.41,14l1.41,1.41c0.39,0.39,0.39,1.02,0,1.41 s-1.02,0.39-1.41,0L12,15.41l-1.41,1.41c-0.39,0.39-1.02,0.39-1.41,0c-0.39-0.39-0.39-1.02,0-1.41L10.59,14L9.17,12.59z M18,4h-2.5 l-0.71-0.71C14.61,3.11,14.35,3,14.09,3H9.91c-0.26,0-0.52,0.11-0.7,0.29L8.5,4H6C5.45,4,5,4.45,5,5s0.45,1,1,1h12 c0.55,0,1-0.45,1-1S18.55,4,18,4z" />
          </g>
        </svg>
      </div>
      <p>
        <span className="event-card-menu">status:</span>
        <span
          className={`${
            event.active ? "event-active-span " : ""
          }single-event-status-show`}
        >
          {event.active ? (
            <span className="event-active-inner">
              <span className="active-dot-green"></span>Active
            </span>
          ) : (
            <>Inactive</>
          )}
        </span>
      </p>
      <span className="event-card-menu">Message:</span>
      <span className="announcement-msg">{event.message}</span>

      <span className="event-card-menu">Created:</span>
      <span className="event-card-menu "> {event.localTimeString}</span>
    </div>
  );
};

const AnnouncementGrid = ({
  events,
  copyEvent,
  loadingForCopyingEvent,
  updateEvent,
  setUpdateEvent,
  handleAnnouncementStatusChange,
}) => {
  console.log(events);
  const [EditEventModalOpen, setEditEventModalOpen] = useState(false);
  const [showDeleteEventConfirmationMsg, setDeleteEventConfirmationMsg] =
    useState(false);
  const [editEventIndex, seteditEventIndex] = useState(-1);
  const [deleteEventIndex, setdeleteEventIndex] = useState(-1);
  return (
    <div className="event-grid">
      {events.length > 0 &&
        events.map((event, index) => (
          <AnnouncementCard
            key={event.id}
            event={event}
            index={index}
            seteditEventIndex={seteditEventIndex}
            setEditEventModalOpen={setEditEventModalOpen}
            setDeleteEventConfirmationMsg={setDeleteEventConfirmationMsg}
            setdeleteEventIndex={setdeleteEventIndex}
            handleAnnouncementStatusChange={handleAnnouncementStatusChange}
          />
        ))}
      {events.length === 0 && <AnnouncementCard event={""} />}
      {EditEventModalOpen ? (
        <EditAnnouncement
          isOpen={EditEventModalOpen}
          onClose={() => setEditEventModalOpen(false)}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          announcement={events && events[editEventIndex]}
        />
      ) : null}
      {showDeleteEventConfirmationMsg ? (
        <AnnouncementDeleteConfirmation
          isOpen={showDeleteEventConfirmationMsg}
          onClose={() => setDeleteEventConfirmationMsg(false)}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          event={events && events[deleteEventIndex]}
        />
      ) : null}
    </div>
  );
};

export default AnnouncementGrid;
