import axios, { AxiosError } from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: { "Content-Type": "application/json" },
});
api.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("token"));
  config.headers.Authorization = token ? `${token}` : "";
  return config;
});
// defining a custom error handler for all APIsy
const errorHandler = (error) => {
  const statusCode = error.response?.status;
  if (error.code === "ERR_CANCELED") return Promise.resolve();
  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) console.error(error);
  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});
