import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import EditEvent from "./quiz/EditEvent";
import EventDeleteConfirmation from "./quiz/EventDeleteConfirmation";

const EventCard = ({
  event,
  index,
  copyEvent,
  loadingForCopyingEvent,
  seteditEventIndex,
  setEditEventModalOpen,
  setDeleteEventConfirmationMsg,
  setdeleteEventIndex,
}) => {
  const token = useSelector((state) => state.user?.token);
  const [totalQuestions, setTotalQuestions] = useState(0);

  console.log(event);

  useEffect(() => {
    // Fetch the total number of questions for the event
    const fetchTotalQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASEURL}/questions/getTotalQuestionsByQuizId?quizId=${event.id}`,
          {
            headers: {
              Authorization: token,
              // Add any other headers if needed
            },
          }
        );
        console.log(response.data?.response?.result?.totalQuestions);
        setTotalQuestions(response.data?.response?.result?.totalQuestions);
      } catch (error) {
        console.error("Error fetching total questions:", error);
      }
    };

    fetchTotalQuestions();
  }, [event.id, token]);

  return (
    <div className="event-card" key={index}>
      <div className="eventCopyIcon">
        {loadingForCopyingEvent?.status &&
        loadingForCopyingEvent?.index === index ? (
          <svg
            width="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="animate-spin"
          >
            <path
              opacity="0.5"
              d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17316C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761204C12.6136 0.258658 11.3132 0 10 0V0ZM10 18C8.41775 18 6.87103 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99112 2.15372 8.43928C2.4624 6.88743 3.22433 5.46196 4.34315 4.34314C5.46197 3.22432 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21446 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6568C14.1566 17.1571 12.1217 18 10 18V18Z"
              fill="#7E9195"
            ></path>
            <path
              d="M18 10H20C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10Z"
              fill="currentColor"
            ></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            onClick={() => copyEvent(event.id, index)}
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <path d="M15,20H5V7c0-0.55-0.45-1-1-1h0C3.45,6,3,6.45,3,7v13c0,1.1,0.9,2,2,2h10c0.55,0,1-0.45,1-1v0C16,20.45,15.55,20,15,20z M20,16V4c0-1.1-0.9-2-2-2H9C7.9,2,7,2.9,7,4v12c0,1.1,0.9,2,2,2h9C19.1,18,20,17.1,20,16z M18,16H9V4h9V16z" />
            </g>
          </svg>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
          onClick={() => {
            seteditEventIndex(index - 1);
            setEditEventModalOpen(true);
          }}
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
          onClick={() => {
            setDeleteEventConfirmationMsg(true);
            setdeleteEventIndex(index - 1);
          }}
        >
          <g>
            <path d="M0,0h24v24H0V0z" fill="none" />
          </g>
          <g>
            <path d="M6,19c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V7H6V19z M9.17,12.59c-0.39-0.39-0.39-1.02,0-1.41c0.39-0.39,1.02-0.39,1.41,0 L12,12.59l1.41-1.41c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L13.41,14l1.41,1.41c0.39,0.39,0.39,1.02,0,1.41 s-1.02,0.39-1.41,0L12,15.41l-1.41,1.41c-0.39,0.39-1.02,0.39-1.41,0c-0.39-0.39-0.39-1.02,0-1.41L10.59,14L9.17,12.59z M18,4h-2.5 l-0.71-0.71C14.61,3.11,14.35,3,14.09,3H9.91c-0.26,0-0.52,0.11-0.7,0.29L8.5,4H6C5.45,4,5,4.45,5,5s0.45,1,1,1h12 c0.55,0,1-0.45,1-1S18.55,4,18,4z" />
          </g>
        </svg>
      </div>
      <h3>{event.Name}</h3>
      <p>
        <span className="event-card-menu">status:</span> {event.status}
      </p>
      <p>Total Questions: {totalQuestions}</p>
      <p>isFinished: {event.isFinished}</p>
      <p>Created: {event.localTimeString}</p>
      <div className="eventCardLinks">
        <Link to={`/events/${event.id}`}>View Details</Link>
        <Link to={`/event/result/${event.id}`}>Result Stat's</Link>
      </div>
    </div>
  );
};

const EventGrid = ({
  events,
  copyEvent,
  loadingForCopyingEvent,
  updateEvent,
  setUpdateEvent,
}) => {
  const [EditEventModalOpen, setEditEventModalOpen] = useState(false);
  const [showDeleteEventConfirmationMsg, setDeleteEventConfirmationMsg] =
    useState(false);
  const [editEventIndex, seteditEventIndex] = useState(-1);
  const [deleteEventIndex, setdeleteEventIndex] = useState(-1);
  return (
    <div className="event-grid">
      {events.length > 0 &&
        events.map((event, index) => (
          <EventCard
            key={event.id}
            event={event}
            index={index + 1}
            copyEvent={copyEvent}
            loadingForCopyingEvent={loadingForCopyingEvent}
            seteditEventIndex={seteditEventIndex}
            setEditEventModalOpen={setEditEventModalOpen}
            setDeleteEventConfirmationMsg={setDeleteEventConfirmationMsg}
            setdeleteEventIndex={setdeleteEventIndex}
          />
        ))}
      {events.length === 0 && <EventCard event={""} />}
      {EditEventModalOpen ? (
        <EditEvent
          isOpen={EditEventModalOpen}
          onClose={() => setEditEventModalOpen(false)}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          event={events && events[editEventIndex]}
        />
      ) : null}
      {showDeleteEventConfirmationMsg ? (
        <EventDeleteConfirmation
          isOpen={showDeleteEventConfirmationMsg}
          onClose={() => setDeleteEventConfirmationMsg(false)}
          setUpdateEvent={setUpdateEvent}
          updateEvent={updateEvent}
          event={events && events[deleteEventIndex]}
        />
      ) : null}
    </div>
  );
};

export default EventGrid;
