import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../styles/UserLogin.css";
import { Toast, ToastType } from "../../utils";
import { ResponseStatus } from "../../apis/utils/interfaces/enum";
import { useDispatch, useSelector } from "react-redux";

function UserLogin() {
  const [formData, setFormData] = useState({
    userName: "",
    mobile: "",
    city: "",
    state: "",
    country: "",
    deviceId: "",
    status: 1,
    email: "user@gmail.com",
  });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASEURL}/candidate/signup`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.response?.result?.data);
      setResponse(response.data);
      if (
        response.data.response?.status &&
        response.data.response?.responseStatus === ResponseStatus.Success
      ) {
        dispatch({
          type: "SET_USER",
          payload: response.data.response?.result?.data,
        });
        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response.data.response?.result?.data)
        );
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.response?.result?.data?.token)
        );
        Toast("Login  successful  ", ToastType.SUCCESS, 5000);
        window.location.reload();
      } else if (!response.data.response?.status) {
        Toast(response.data.response?.message, ToastType.ERROR, 5000);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // You may want to use this effect for additional actions upon successful response or other scenarios.
    // For now, it's just logging the response.
    if (response) {
      console.log(response);
    }
  }, [response]);

  return (
    <div className="sign-up-container">
      <h2>Sign Up</h2>
      <form className="sign-up-form">
        <div className="form-group">
          <label htmlFor="userName">Name:</label>
          <input
            type="text"
            id="userName"
            value={formData.userName}
            onChange={(e) =>
              setFormData({ ...formData, userName: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile:</label>
          <input
            type="text"
            id="mobile"
            value={formData.mobile}
            onChange={(e) =>
              setFormData({ ...formData, mobile: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="city">City:</label>
          <input
            type="text"
            id="city"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="state">State:</label>
          <input
            type="text"
            id="state"
            value={formData.state}
            onChange={(e) =>
              setFormData({ ...formData, state: e.target.value })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="country">Country:</label>
          <input
            type="text"
            id="country"
            value={formData.country}
            onChange={(e) =>
              setFormData({ ...formData, country: e.target.value })
            }
          />
        </div>

        <button
          type="button"
          className="button action-btn"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Signing Up..." : "Sign Up"}
        </button>
      </form>
      {error && <div className="error-message">{error.message}</div>}
    </div>
  );
}

export default UserLogin;
