import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Toast, ToastType } from "../../utils";
import CustomModal from "../Modal";

function QuestionDeleteConfirmation({
  isOpen,
  onClose,
  setUpdateEvent,
  updateEvent,
  question,
}) {
  const token = useSelector((state) => state.user?.token);
  console.log(question);
  const handleQuestionDelete = async (questionId) => {
    try {
      // Make API call to delete question
      await axios.delete(
        `${process.env.REACT_APP_API_BASEURL}/questions/deleteQuestion?id=${questionId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      Toast("Question deleted successfully", ToastType.SUCCESS, 2000);
      setUpdateEvent({ ...updateEvent, questions: !updateEvent.questions });
      onClose();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="create-quiz-event-container">
        <h3>Are you sure you want to delete this question?</h3>

        <div className="form-group">
          <span className="delete-quiz-span-title">Question:</span>
          <p>{question ? question.question : ""}</p>
        </div>

        <div className="form-group">
          <span className="delete-quiz-span-title">Options:</span>
          {/* <p>{question ? question.options : ""}</p> */}
          <ul>
            {question &&
              question.options.map((item, index) => (
                <li key={index}>{item.optionsName}</li>
              ))}
          </ul>
        </div>
        <button
          className="button delete-button"
          onClick={() => handleQuestionDelete(question && question.id)}
        >
          Confirm Delete
        </button>
      </div>
    </CustomModal>
  );
}

export default QuestionDeleteConfirmation;
