import toast from "react-hot-toast";
const ToastPosition = {
  TOP_CENTER: "top-center",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_CENTER: "bottom-center",
};
export const ToastType = {
  INFO: "INFO",
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
  DEFAULT: "DEFAULT",
};
export const Toast = (
  message = "Something went wrong",

  type = ToastType.DEFAULT,
  autoClose = 5000,
  position = ToastPosition.TOP_CENTER,
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true
) => {
  const toastObject = {
    position,
    duration: autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  switch (type) {
    case ToastType.SUCCESS:
      return toast.success(message, toastObject);
    case ToastType.ERROR:
      return toast.error(message, toastObject);
    case ToastType.DEFAULT:
      return toast(message, toastObject);
    default:
      return toast.error(message, toastObject);
  }
};

export const ToastSuccessSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
      <path
        fill="#fff"
        d="m8.542 14.723 6.98-6.98-1.384-1.371-5.596 5.596L5.77 9.195l-1.358 1.372 4.131 4.156Zm1.453 5.397c-1.375 0-2.67-.26-3.885-.782a10.066 10.066 0 0 1-3.185-2.144 10.073 10.073 0 0 1-2.143-3.182A9.739 9.739 0 0 1 0 10.128c0-1.39.26-2.694.782-3.909a9.95 9.95 0 0 1 2.141-3.173c.907-.9 1.967-1.613 3.182-2.139A9.684 9.684 0 0 1 9.99.12c1.392 0 2.696.262 3.912.786a10.096 10.096 0 0 1 3.174 2.136c.9.899 1.612 1.956 2.137 3.172A9.764 9.764 0 0 1 20 10.126a9.691 9.691 0 0 1-.788 3.887 10.23 10.23 0 0 1-2.138 3.183 9.951 9.951 0 0 1-3.172 2.142 9.798 9.798 0 0 1-3.907.782Zm-.002-1.912c2.248 0 4.157-.786 5.727-2.358 1.57-1.572 2.355-3.48 2.355-5.724 0-2.248-.785-4.156-2.355-5.726-1.57-1.57-3.481-2.355-5.734-2.355-2.236 0-4.141.785-5.714 2.355-1.574 1.57-2.36 3.48-2.36 5.733 0 2.237.786 4.142 2.358 5.715 1.572 1.573 3.48 2.36 5.723 2.36Z"
      />
    </svg>
  );
};
export const ToastErrorSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none">
      <path
        d="M7.12531 15.1784L10.6358 11.668L14.1462 15.1784L15.2688 14.0558L11.7584 10.5454L15.2688 7.03494L14.1462 5.91234L10.6358 9.42279L7.12531 5.91234L6.00271 7.03494L9.51316 10.5454L6.00271 14.0558L7.12531 15.1784ZM10.6376 20.5386C9.2627 20.5386 7.96765 20.278 6.75249 19.7569C5.53734 19.2357 4.4758 18.5212 3.56787 17.6133C2.65995 16.7054 1.94542 15.6444 1.42429 14.4305C0.903147 13.2166 0.642578 11.9222 0.642578 10.5473C0.642578 9.15625 0.903147 7.85315 1.42429 6.63799C1.94542 5.42284 2.65924 4.36509 3.56575 3.46473C4.47223 2.56438 5.53276 1.85159 6.74733 1.32639C7.96189 0.801179 9.25703 0.538574 10.6327 0.538574C12.0245 0.538574 13.3285 0.800824 14.5448 1.32532C15.761 1.84983 16.819 2.56165 17.7186 3.4608C18.6183 4.35995 19.3306 5.41728 19.8554 6.6328C20.3802 7.84832 20.6426 9.15252 20.6426 10.5454C20.6426 11.9216 20.38 13.2172 19.8548 14.4322C19.3296 15.6472 18.6168 16.7081 17.7164 17.6149C16.8161 18.5217 15.7589 19.2357 14.545 19.7569C13.3311 20.278 12.0287 20.5386 10.6376 20.5386ZM10.6358 18.627C12.8837 18.627 14.7927 17.8409 16.3626 16.2687C17.9324 14.6965 18.7174 12.7887 18.7174 10.5454C18.7174 8.29741 17.9324 6.38848 16.3626 4.8186C14.7927 3.24872 12.8815 2.46378 10.6289 2.46378C8.3925 2.46378 6.48759 3.24872 4.91421 4.8186C3.34083 6.38848 2.55415 8.29969 2.55415 10.5522C2.55415 12.7886 3.34025 14.6936 4.91247 16.2669C6.48468 17.8403 8.39245 18.627 10.6358 18.627Z"
        fill="#fff"
      />
    </svg>
  );
};

export const isValidUser = () => {
  let token = localStorage.getItem("token");
  return token;
};

export const StartIcon = ({ onClick, datatooltipid, datatooltipcontent }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="30px"
      viewBox="0 0 24 24"
      width="30px"
      fill="#000000"
      className="start-icon"
      onClick={onClick}
      data-tooltip-id={datatooltipid}
      data-tooltip-content={datatooltipcontent}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M9.5,14.67V9.33c0-0.79,0.88-1.27,1.54-0.84 l4.15,2.67c0.61,0.39,0.61,1.29,0,1.68l-4.15,2.67C10.38,15.94,9.5,15.46,9.5,14.67z" />
      </g>
    </svg>
  );
};

export const StopIcon = ({ onClick, datatooltipid, datatooltipcontent }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="30px"
      viewBox="0 0 24 24"
      width="30px"
      className="stop-icon"
      fill="#000000"
      onClick={onClick}
      data-tooltip-id={datatooltipid}
      data-tooltip-content={datatooltipcontent}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M10,16L10,16c-0.55,0-1-0.45-1-1V9 c0-0.55,0.45-1,1-1l0,0c0.55,0,1,0.45,1,1v6C11,15.55,10.55,16,10,16z M14,16L14,16c-0.55,0-1-0.45-1-1V9c0-0.55,0.45-1,1-1l0,0 c0.55,0,1,0.45,1,1v6C15,15.55,14.55,16,14,16z" />
        </g>
      </g>
    </svg>
  );
};

export const DeleteOption = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
      onClick={onClick}
      className="deleteOptionSvg"
    >
      <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
    </svg>
  );
};

export const IsCorrectOption = ({ onClick, className, checked }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 96 96"
      checked={checked}
      onClick={onClick}
      className={`${className} isCorrectsvg`}
    >
      <title />
      <g>
        <path d="M58.3945,32.1563,42.9961,50.625l-5.3906-6.4629a5.995,5.995,0,1,0-9.211,7.6758l9.9961,12a5.9914,5.9914,0,0,0,9.211.0059l20.0039-24a5.9988,5.9988,0,1,0-9.211-7.6875Z" />

        <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z" />
      </g>
    </svg>
  );
};

export const AddQuestionSVG = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="addQuestionSVG"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        sketchtype="MSPage"
      >
        <g
          id="Icon-Set-Filled"
          sketchtype="MSLayerGroup"
          transform="translate(-466.000000, -1089.000000)"
          fill="#000000"
        >
          <path
            d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z"
            id="plus-circle"
            sketchtype="MSShapeGroup"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const DeleteQuestionSVG = ({
  onClick,
  datatooltipid,
  datatooltipcontent,
}) => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="deleteQuestionSVG"
      data-tooltip-id={datatooltipid}
      data-tooltip-content={datatooltipcontent}
    >
      <path
        d="M8.00386 9.41816C7.61333 9.02763 7.61334 8.39447 8.00386 8.00395C8.39438 7.61342 9.02755 7.61342 9.41807 8.00395L12.0057 10.5916L14.5907 8.00657C14.9813 7.61605 15.6144 7.61605 16.0049 8.00657C16.3955 8.3971 16.3955 9.03026 16.0049 9.42079L13.4199 12.0058L16.0039 14.5897C16.3944 14.9803 16.3944 15.6134 16.0039 16.0039C15.6133 16.3945 14.9802 16.3945 14.5896 16.0039L12.0057 13.42L9.42097 16.0048C9.03045 16.3953 8.39728 16.3953 8.00676 16.0048C7.61624 15.6142 7.61624 14.9811 8.00676 14.5905L10.5915 12.0058L8.00386 9.41816Z"
        fill="#0F0F0F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"
        fill="#0F0F0F"
      />
    </svg>
  );
};

export const BackArrowSVG = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
      onClick={onClick}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </svg>
  );
};

export const AnnouncementSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="currentColor"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <path d="M18,12L18,12c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-2C18.45,11,18,11.45,18,12z" />
      <path d="M16.59,16.82c-0.33,0.44-0.24,1.05,0.2,1.37c0.53,0.39,1.09,0.81,1.62,1.21c0.44,0.33,1.06,0.24,1.38-0.2 c0-0.01,0.01-0.01,0.01-0.02c0.33-0.44,0.24-1.06-0.2-1.38c-0.53-0.4-1.09-0.82-1.61-1.21c-0.44-0.33-1.06-0.23-1.39,0.21 C16.6,16.81,16.59,16.82,16.59,16.82z" />
      <path d="M19.81,4.81c0-0.01-0.01-0.01-0.01-0.02c-0.33-0.44-0.95-0.53-1.38-0.2c-0.53,0.4-1.1,0.82-1.62,1.22 c-0.44,0.33-0.52,0.95-0.19,1.38c0,0.01,0.01,0.01,0.01,0.02c0.33,0.44,0.94,0.53,1.38,0.2c0.53-0.39,1.09-0.82,1.62-1.22 C20.05,5.87,20.13,5.25,19.81,4.81z" />
      <path d="M8,9H4c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2h1v3c0,0.55,0.45,1,1,1h0c0.55,0,1-0.45,1-1v-3h1l5,3V6L8,9z" />
      <path d="M15.5,12c0-1.33-0.58-2.53-1.5-3.35v6.69C14.92,14.53,15.5,13.33,15.5,12z" />
    </svg>
  );
};

export const SortingIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="48px"
      viewBox="0 0 30 47"
      version="1.1"
      className="sortingIconSVG"
    >
      <g id="surface1">
        <path d="M 27.503906 19.449219 L 2.492188 19.449219 C 1.914062 19.445312 1.332031 19.25 0.859375 18.84375 C -0.175781 17.953125 -0.285156 16.402344 0.617188 15.378906 L 13.445312 0.84375 C 13.535156 0.742188 13.628906 0.648438 13.734375 0.5625 C 14.792969 -0.300781 16.363281 -0.15625 17.234375 0.890625 L 29.339844 15.332031 C 29.761719 15.785156 29.996094 16.375 29.992188 16.992188 C 29.992188 18.347656 28.878906 19.449219 27.503906 19.449219 Z M 2.492188 27.550781 L 27.503906 27.550781 C 28.878906 27.550781 29.992188 28.652344 29.992188 30.007812 C 29.992188 30.648438 29.746094 31.230469 29.339844 31.667969 L 17.234375 46.109375 C 16.363281 47.15625 14.792969 47.300781 13.734375 46.4375 C 13.628906 46.351562 13.535156 46.257812 13.445312 46.15625 L 0.617188 31.621094 C -0.285156 30.597656 -0.175781 29.046875 0.859375 28.15625 C 1.332031 27.75 1.914062 27.554688 2.492188 27.550781 Z M 2.492188 27.550781 " />
      </g>
    </svg>
  );
};
