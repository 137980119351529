import { GET, POST } from '../../utils/Requests';

import { QuizEndpoint, userAuthEndPoints } from '../../endpoints';

export const projectQuizHandler = {
    getAllEventAPI,
    createEventAPI,
    getAllQuizAPI,
    createQuizAPI,
    createQuecations,
    getAllQuecations,
    getQueByID,
    AddNewOptions,
    updateVisibility,
    getQuizById,
    getActiveQue,
    createAnswer,
    getActiveResult,
    getQuizNavigateURL,
    getQuizNameByID,
    UpdateResultStatusURL,
    createQueWithOptions,
    generateQuizResult
};
// async function registerAPI(object: any) {
//     try {
//         return await POST(userAuthEndPoints.REGISTER_USER, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('registerAPI'), error);
//     }
// }
//
// async function checkIfEmailAlreadyRegisteredAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.CHECK_EMAIL_ALREADY_REGISTERED, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('checkIfEmailAlreadyRegisteredAPI'), error);
//     }
// }
async function getQuizNameByID(object = {}) {
    try {
        return await GET(QuizEndpoint.getQuizNameByid, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function generateQuizResult(object = {}) {
    try {
        return await POST(QuizEndpoint.generateQuizResult, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function getActiveResult(object = {}) {
    try {
        return await POST(QuizEndpoint.GET_ACTIVE_RESULT, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function getQuizNavigateURL(object = {}) {
    try {
        return await GET(QuizEndpoint.GET_QUIZ_NAVIGATE_URL, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function UpdateResultStatusURL(object = {}) {
    try {
        return await GET(QuizEndpoint.UPDATE_RESULT_STATUS, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function createAnswer(object = {}) {
    try {
        return await POST(QuizEndpoint.CREATE_ANSWER, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function getActiveQue(object = {}) {
    try {
        return await POST(QuizEndpoint.GET_ACTIVE_QUE, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function updateVisibility(object = {}) {
    try {
        return await POST(QuizEndpoint.UPDATE_VISIBILITY, object);
    } catch (error) {
        console.log('updateVisibility', error);
    }
}
async function createQuecations(object = {}) {
    try {
        return await POST(QuizEndpoint.CREATE_QUE, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function getQuizById(object = {}) {
    try {
        return await GET(QuizEndpoint.GET_QUIZ_BY_ID, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function AddNewOptions(object = {}) {
    try {
        return await POST(QuizEndpoint.ADD_NEW_OPTION, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function getAllQuecations(object = {}) {
    try {
        return await GET(QuizEndpoint.GET_ALL_QUE, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function getQueByID(object = {}) {
    try {
        return await GET(QuizEndpoint.GET_QUE_BY_ID, object);
    } catch (error) {
        console.log('login', error);
    }
}

async function getAllEventAPI(object = {}) {
    try {
        return await GET(QuizEndpoint.GET_ALL_EVENT, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function getAllQuizAPI(object = {}) {
    try {
        return await GET(QuizEndpoint.GETALL_QUIZ, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function createEventAPI(object = {}) {
    try {
        return await POST(QuizEndpoint.CREATE_EVENT, object);
    } catch (error) {
        console.log('login', error);
    }
}

async function createQuizAPI(object = {}) {
    try {
        return await POST(QuizEndpoint.CREATE_QUIZ, object);
    } catch (error) {
        console.log('login', error);
    }
}
async function createQueWithOptions(object = {}) {
    try {
        return await POST(QuizEndpoint.CreateQuecationWithOption, object);
    } catch (error) {
        console.log('login', error);
    }
}
// async function logoutAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.LOGOUT, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('loginAPI'), error);
//     }
// }
// async function ResendEmailConfirmationLinkAPI(object: any) {
//     try {
//         return await GET(userAuthEndPoints.RESEND_EMAIL_CONFIRM_URL, object);
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('ResendEmailConfirmationLinkAPI'), error);
//     }
// }

// async function ForgotPasswordAPI(object: any) {
//     try {
//         return await POST(AddQueryStringToEndPoint(userAuthEndPoints.FORGOT_PASSWORD, object));
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('ForgotPasswordAPI'), error);
//     }
// }
// async function resetPasswordAPI(object: any) {
//     try {
//         const responseOBJ = new commanResponse();
//
//         //schema for payload
//         const resetPasswordSchema = Yup.object({
//             token: Yup.string().required(CONFIG.MESSAGE.REQUIRED_TOKEN),
//             email: Yup.string().email(CONFIG.MESSAGE.INVALID_EMAIL).required(),
//             password: Yup.string().required(CONFIG.MESSAGE.PASSWORD_REQUIRE),
//             confirmPassword: Yup.string().required(CONFIG.MESSAGE.CONFIRM_PASSWORD_REQUIRE)
//         });
//
//         if (await resetPasswordSchema.isValid(object)) return await POST(userAuthEndPoints.RESET_PASSWORD, object);
//         else {
//             responseOBJ.ResponseStatus = ResponseStatus.UnprocessableEntity;
//             responseOBJ.Status = false;
//             responseOBJ.Message = await resetPasswordSchema.validate(object).catch((e: any) => e.message);
//         }
//         return responseOBJ;
//     } catch (error) {
//         console.log(CONFIG.MESSAGE.CATCH_ERROR_FROM('resetPasswordAPI'), error);
//     }
// }
