import React from "react";
import "../../styles/QuizResult.css";
import correctIcon from "../../assets/check_black_24dp.svg";
import { AnnouncementSVG } from "../../utils";

const QuizResults = ({
  userResults,
  questionsWithOptions,
  singleEventDetailsForResult,
  summary,
  announcementMsg,
}) => {
  console.log(summary ? summary : null);

  const notAttemptedCount =
    questionsWithOptions?.length -
    (summary?.correctCount + summary?.wrongCount);
  console.log(notAttemptedCount, "notAttemptedCount");
  return (
    <div>
      {announcementMsg ? (
        <>
          <div className="live-event-with-question announcement-parent">
            <span className=" client-announcement-title">
              <AnnouncementSVG /> Announcement
            </span>
            <p className="client-announcement">{announcementMsg}</p>
          </div>
        </>
      ) : null}
      <h2 className="event-tag">Result</h2>
      <div className="resultCubes">
        <div className="correctCube">
          {summary?.correctCount}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className="icon-image"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z" />
          </svg>
        </div>
        <div className="wrongCube">
          {summary?.wrongCount}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className="icon-image"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
          </svg>
        </div>
        <div className="notAttemptedCube">
          {notAttemptedCount}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
            className="icon-image"
          >
            <path d="M431-330q1-72 16.5-105t58.5-72q42-38 64.5-70.5T593-647q0-45-30-75t-84-30q-52 0-80 29.5T358-661l-84-37q22-59 74.5-100.5T479-840q100 0 154 55.5T687-651q0 48-20.5 87T601-482q-49 47-59 72t-11 80H431Zm48 250q-29 0-49.5-20.5T409-150q0-29 20.5-49.5T479-220q29 0 49.5 20.5T549-150q0 29-20.5 49.5T479-80Z" />
          </svg>
        </div>
      </div>
      <div className="event-title">
        <h1>{singleEventDetailsForResult.Name}</h1>
      </div>
      {questionsWithOptions.map((question) => {
        const userResult = userResults.find(
          (result) => result.questionId === question.id
        );

        return (
          <div key={question.id}>
            <div className="live-event-with-question">
              <h3>{question.question}</h3>
              <br />
              {/* {userResult ? (
                <React.Fragment>
                  Your Selection: {userResult.selectedOptionId}
                  <br />
                  Correctness: {userResult.correctness}
                </React.Fragment>
              ) : (
                "Your Selection: Not Attempted"
              )}
              <br />
              Options: */}
              {/* <ul>
                  {question.options.map((option) => (
                    <li key={option.id}>
                      {option.optionsName} -{" "}
                      {option.isCorrect ? "Correct" : "Incorrect"}
                    </li>
                  ))}
                </ul> */}
              <ul>
                {question.options.map((option) => (
                  <li
                    key={option.id}
                    className={`options-li disabled show-result ${
                      userResult &&
                      userResult?.selectedOptionId !== "Not Attempted"
                        ? "attempted"
                        : null
                    } ${
                      userResult &&
                      userResult?.selectedOptionId &&
                      userResult?.selectedOptionId === option.id &&
                      userResult?.selectedOptionId !== "Not Attempted"
                        ? userResult.correctness
                        : null
                    } ${option.isCorrect ? "correct" : ""}`}
                    id={`nohover ${
                      userResult &&
                      userResult?.selectedOptionId !== "Not Attempted"
                        ? "attempted"
                        : null
                    }`}
                  >
                    <label>
                      <input
                        type="radio"
                        id={`option-${option.id}`}
                        name={`option-${option.id}`}
                        defaultChecked={
                          (userResult &&
                            userResult?.selectedOptionId !== "Not Attempted" &&
                            option.isCorrect) ||
                          userResult?.selectedOptionId === option.id
                        }
                        className={`${
                          userResult?.selectedOptionId === option.id
                            ? "correctInput"
                            : ""
                        }`}
                      />
                      {option.optionsName}
                    </label>
                    {option.isCorrect ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="currentColor"
                        className="icon-image"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z" />
                      </svg>
                    ) : null}
                    {userResult &&
                    userResult?.selectedOptionId &&
                    userResult?.selectedOptionId === option.id &&
                    userResult?.selectedOptionId !== "Not Attempted" &&
                    userResult.correctness === "wrong" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#ffffff"
                        className="icon-image"
                      >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
                      </svg>
                    ) : null}
                  </li>
                ))}
              </ul>
              <div className="submit-btn-container">
                <button
                  type="submit"
                  className="submit-btn"
                  disabled
                  id={
                    userResult?.correctness &&
                    userResult?.correctness === "correct"
                      ? "correctBtn"
                      : userResult?.correctness === "wrong"
                      ? "wrongBtn"
                      : ""
                  }
                >
                  {userResult?.correctness &&
                  userResult?.correctness === "correct"
                    ? "Correct"
                    : userResult?.correctness === "wrong"
                    ? "Wrong"
                    : "Not Attempted"}
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuizResults;
