// Navbar.js
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DarkModeToggle from "../DarkModeToggle";

const UserNavbar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // console.log(user);
  const navigate = useNavigate();
  const handleLogout = () => {
    // Implement your logout logic here
    // For example, clear user data from localStorage and redirect to login page
    localStorage.removeItem("UserInfo");
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" });
    window.location.reload();
    // navigate("/home");
  };

  return (
    <div className="navbar">
      <div style={styles.leftSection}>
        {/* Profile button (link to user profile page, adjust the path accordingly) */}
        {user && user.profile ? <Link to="/user/profile">Profile</Link> : null}
      </div>
      <div style={styles.rightSection}>
        {/* Logout button */}
        {user && user.profile ? (
          <div className="navbarRight">
            {/* <button onClick={handleLogout} style={styles.logoutButton}>
              Logout
            </button> */}
            <DarkModeToggle />
          </div>
        ) : (
          <div className="navbarRight">
            {/* <button
              onClick={() => navigate("/login")}
              style={styles.loginButton}
            >
              Login
            </button> */}
            <DarkModeToggle />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#333",
    color: "#fff",
  },
  leftSection: {
    flex: 1,
    textAlign: "left",
  },
  rightSection: {
    flex: 1,
    textAlign: "right",
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    margin: "0 10px",
  },
  logoutButton: {
    cursor: "pointer",
    padding: "5px 10px",
    background: "none",
    border: "1px solid #fff",
    borderRadius: "5px",
    color: "#fff",
  },
};

export default UserNavbar;
