import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Toast, ToastType } from "../../utils";
import CustomModal from "../Modal";

function EditAnnouncement({
  isOpen,
  onClose,
  setUpdateEvent,
  updateEvent,
  announcement,
}) {
  const token = useSelector((state) => state.user?.token);
  const [message, setMessage] = useState(
    announcement ? announcement.message : ""
  ); // State for quiz name

  const EditAnnouncement = async () => {
    try {
      // Create a new quiz event
      const createQuizResponse = await axios.put(
        `${process.env.REACT_APP_API_BASEURL}/announcement/updateAnnouncement`,
        {
          announcementId: announcement?.id,
          message: message,
          // Include other required fields in the request body
        },
        {
          headers: {
            Authorization: token,
            // Include any other required headers
          },
        }
      );

      console.log("Announcement updated successfully", createQuizResponse.data);
      Toast("Announcement updated successfully", ToastType.SUCCESS, 2000);
      setUpdateEvent(!updateEvent);
      onClose();
    } catch (error) {
      console.error(
        "Error updating Announcement:",
        error.response ? error.response.data : error.message
      );
    }
  };
  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div className="create-quiz-event-container">
        <h2>Update Announcement</h2>

        <div className="form-group">
          <label>Message:</label>
          <textarea
            rows={10}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <button className="button" onClick={EditAnnouncement}>
          Save Announcement
        </button>
      </div>
    </CustomModal>
  );
}

export default EditAnnouncement;
